import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import { GoArrowLeft } from "react-icons/go";
import {
  ExplorerTabs,
  UserDetailsResponse,
  walletType,
  walletType2,
} from "./hooks/types";
import { getUserDetails } from "./hooks/helper";
import { ReferralPageModal } from "./Onboard";
import { resetUser, updateUser } from "../../controller/profile/user";
import { useAppDispatch, useAppSelector } from "../../controller/state/hooks";
import { ToastContainer } from "react-toastify";
interface PrevHeaderProps {
  pubAddress: string | null;
  walletType?: string | null;
  onDisconnect: () => void;
  onConnect?: () => void;
  backbutton?: boolean;
  title: ExplorerTabs | string;
}

const walletIcons: Record<string, string> = {
  [walletType2.Phantom]: "/assets/main_v4/phantom.svg",
  [walletType2.Metamask]: "/assets/main_v4/metamask.svg",
  [walletType2.BackPack]: "/assets/main_v4/backpack.svg",
};

const PrevHeader: React.FC<PrevHeaderProps> = ({
  pubAddress,
  walletType,
  onDisconnect,
  onConnect,
  backbutton,
  title,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // const [referralCode, setReferralCode] = useState<string | null>(null);
  // const [rankvalue, setRank] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
    const userDetails = useAppSelector((state) => state.userState.userDetails);
    const referralCode = userDetails?.data?.user?.referral_code || null;
    const rankvalue = userDetails?.data?.point?.rank || null;
  


  const storedWalletType =
    walletType || sessionStorage.getItem("walletType") || "Unknown";
  const walletIcon = walletIcons[storedWalletType] || null;

  const handleOpenReferralModal = () => {
    setReferralModalOpen(true);
  };

  const handleCloseReferralModal = () => {
    setReferralModalOpen(false);
  };

  const truncateAddress = (address: string) =>
    `${address.slice(0, 6)}...${address.slice(-4)}`;

  const copyToClipboard = () => {
    if (referralCode) {
      navigator.clipboard
        .writeText(referralCode)
        .then(() => {
          alert("Referral code copied");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    } else {
      console.warn("No referral code available to copy.");
    }
  };

  const handleDisconnect = () => {
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("token");
    localStorage.removeItem("token");
    dispatch(resetUser());

    onDisconnect();
  };

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);
      const userDetails = await getUserDetails();
      dispatch(updateUser(userDetails));
    } catch (error) {
      console.error("Error fetching user details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleDropdownClick = () => {
    setShowDropdown((prev) => !prev);
    if (!showDropdown) {
      fetchUserDetails();
    }
  };

 

  

  return (
    <header
      className={`z-10 flex items-center ${
        title == "Stake & Earn" ? "justify-center" : "justify-between"
      } pt-12 mb-10 mr-10 bg-transparent`}
    >
      <div className="flex items-start">
        {backbutton && (
          <button
            onClick={() => navigate(-1)}
            className="flex items-center justify-center"
            aria-label="Go Back"
          >
            <GoArrowLeft className="text-white w-[40px] h-[40px] p-2 border border-gray-light-3 rounded-md ml-8" />
          </button>
        )}
        {title != "Stake & Earn" && (
          <h1 className="ml-8 text-xl font-bold md:text-2xl">{title}</h1>
        )}
      </div>
      {title == "Stake & Earn" && (
        <div className="flex flex-col items-center justify-center text-center">
          <h1 className="text-white text-center text-[28px] font-semibold">
            Stake $MON & earn yield with boosted rewards
          </h1>
          <span className="flex text-[28px] text-center font-semibold">
            {" "}
            on <img className="mx-2" src="/assets/main_v4/mmonad.svg" /> testnet
          </span>
        </div>
      )}

      <div className="absolute flex items-center justify-center top-13 right-8">
        <div
          className={`relative flex items-center justify-center text-white px-4 py-[10px] border border-white rounded-[32px] cursor-pointer ${
            !pubAddress && "hover:bg-gray-800"
          }`}
        >
          {pubAddress ? (
            <div
              className="flex items-center h-8 w-[189px] "
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {walletIcon && (
                <img
                  src={walletIcon}
                  alt={`${storedWalletType} wallet`}
                  className="w-6 h-6"
                />
              )}
              <span className="ml-2 mr-4">{truncateAddress(pubAddress)}</span>
              <div onClick={handleDropdownClick}>
                <img src="/assets/main_v4/dropd.svg" />
              </div>
              {/* {isHovered && (
                <div className="absolute z-50 p-2 mt-2 text-sm text-white transform -translate-x-1/2 bg-black rounded-lg shadow-lg bottom-full left-1/2">
                  <p>Address: {pubAddress}</p>
                  <p>{storedWalletType}</p>
                </div>
              )} */}
              {showDropdown && (
                <div className="absolute top-full right-0 mt-2 w-[278px] bg-[#222222] rounded-lg shadow-lg text-white py-4 border border-[#CCCCCC] z-50">
                  <div className="flex flex-col px-6">
                    <div className="flex justify-between ">
                      <div className="border border-[#404040] rounded-[6px] p-2 bg-[#0E0E0E] flex-1 w-[115px] h-[70px] px-[12px] py-[10px] ">
                        <p className="text-[13px] text-[#AAAAAA]">My Rank</p>
                        <p className="text-[18px] flex items-center">
                          {isLoading
                            ? "Loading..."
                            : rankvalue || "Unavailable"}
                        </p>
                      </div>

                      <div className="border border-[#404040] rounded-[6px] p-2 bg-[#0E0E0E] flex-1 ml-2 w-[115px] h-[70px] px-[12px] py-[10px]">
                        <p className="text-[13px] text-[#AAAAAA]">
                          Referral Code
                        </p>
                        <p className="text-[18px] flex items-center">
                          {isLoading
                            ? "Loading..."
                            : referralCode || "Unavailable"}
                          <button onClick={copyToClipboard}>
                            <img
                              src={"/assets/main_v4/coppy.svg"}
                              alt="Copy"
                              className="cursor-pointer"
                            />
                          </button>
                        </p>
                      </div>
                    </div>
                    <img
                    className="w-auto h-auto mt-3"
                    src="assets/main_v4/mintbanner.svg"
                    />
                    <button
                      onClick={handleOpenReferralModal}
                      className="block w-full text-center text-[15px] font-normal mt-4 mb-[16px]  rounded-lg"
                    >
                      Apply Referral
                    </button>

                    <button
                      onClick={handleDisconnect}
                      className="block w-full text-center  text-[15px] mb-[20px]  font-normal rounded-lg "
                    >
                      Disconnect Wallet
                    </button>
                  </div>{" "}
                  <ToastContainer />
                </div>
              )}
            </div>
          ) : (
            <button
              onClick={() => {
                if (onConnect) {
                  setShowDropdown(false);
                  onConnect();
                }
              }}
              className="h-8 w-[189px] px-[30px]"
              aria-label="Connect Wallet"
            >
              Connect Wallet
            </button>
          )}
        </div>
        {isReferralModalOpen && (
          <ReferralPageModal onCancelProcess={handleCloseReferralModal} />
        )}
      </div>
    </header>
  );
};

export default PrevHeader;
