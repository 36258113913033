import React, { useEffect, useState } from "react";
import aporiLogo from "../../assets/V4/apori.svg";
import copyicon from "../../assets/V4/copy.svg";
import HistoryTab from "./Historytab";
import UserRanking from "./UserRanking";
import HistoryTab2 from "./HistoryTab2";
import { getUserDetails } from "./hooks/helper";
import { useQuery } from "@tanstack/react-query";
import { UserDetailsResponse } from "./hooks/types";
import { useAppSelector } from "../../controller/state/hooks";
import { updateUser } from "../../controller/profile/user";
import { useAppDispatch } from "../../controller/state/hooks";
import { toast, ToastContainer } from "react-toastify";
const MyRankAndReferral = () => {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [rankvalue, setRankvalue] = useState<number | null>(null);
  const [pointValue, setPointValue] = useState<number | null>(null);
  const userDetails = useAppSelector((state) => state.userState.userDetails);

  const dispatch = useAppDispatch();
  const copyToClipboard = () => {
    if (referralCode) {
      navigator.clipboard
        .writeText(referralCode)
        .then(() => {
          toast.success("Referral code copied to clipboard!", {
            position: "top-right",
            autoClose: 3000,
            theme: "dark",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
          toast.error("Failed to copy referral code.", {
            position: "top-right",
            autoClose: 3000,
            theme: "dark",
          });
        });
    } else {
      console.warn("No referral code available to copy.");
    }
  };

  useEffect(() => {
    if (userDetails) {
      setReferralCode(
        !userDetails.data.user.referral_code
          ? null
          : userDetails.data.user.referral_code
      );
      setRankvalue(
        userDetails.data.point.rank == 0 ? null : userDetails.data.point.rank
      );
      setPointValue(
        userDetails.data.point.points !== null &&
          userDetails.data.point.points >= 0
          ? userDetails.data.point.points
          : null
      );
    }
  }, [userDetails]);
  useEffect(() => {
    (async () => {
      const userDetails = await getUserDetails();
      dispatch(updateUser(userDetails));
    })();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center p-4 mt-4 mb-4 rounded-lg">
      <ToastContainer />
      <div className="flex items-center justify-center gap-[41px] mb-4">
        {/* My Rank Section */}
        <div className="flex flex-col items-center gap-4">
          <span className="text-white font-semibold text-[23px]">My Rank</span>
          <span className="text-white font-normal text-3xl h-16 w-[136px] border border-[#666666] rounded-lg py-3 px-14 flex items-center justify-center bg-[#222222]">
            {rankvalue !== null ? rankvalue : "-"}
          </span>
        </div>

        {/* <div className="flex flex-col items-center gap-4">
          <span className="text-white font-semibold text-[23px]">
            My Points
          </span>
          <span className="text-white font-normal text-3xl h-16 w-[136px] border border-[#666666] rounded-lg py-3 px-14 flex items-center justify-center bg-[#222222]">
            {pointValue !== null ? pointValue : "-"}
          </span>
        </div> */}

        {/* Referral Code Section */}
        <div className="flex flex-col items-center gap-4">
          <span className="text-white font-semibold text-[23px] ">
            My Referral Code
          </span>
          <div className="flex items-center justify-center gap-4 border border-[#666666]  w-[253px] rounded-lg py-3 px-14 bg-[#222222]">
            <span className="text-3xl text-white">
              {" "}
              {referralCode !== null ? referralCode : "-"}{" "}
            </span>
            {referralCode !== null && (
              <img
                src={copyicon}
                alt="Copy"
                className="cursor-pointer"
                onClick={copyToClipboard}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

 

const Ranking = () => {
  const [activeTab, setActiveTab] = useState<"history" | "ranking">("ranking");
  return (
    <div className="min-h-screen text-white bg-black ">
      <MyRankAndReferral />

      <div className="flex flex-col items-center w-full">
        {/* Button Group */}
        <div className="flex space-x-4">
          <button
            className={`p-2 w-[168px] text-base font-normal transition ${
              activeTab === "history"
                ? "text-[#B882FF] border-b-2 border-spacing-2 border-[#B882FF]"
                : "text-[#fff]"
            }`}
            onClick={() => setActiveTab("history")}
          >
            My history
          </button>
          <button
            className={`p-2 w-[168px] text-base font-normal transition ${
              activeTab === "ranking"
                ? "text-[#B882FF] border-b-2 border-spacing-2 border-[#B882FF]"
                : "text-[#fff]"
            }`}
            onClick={() => setActiveTab("ranking")}
          >
            Rankings
          </button>
        </div>
        {/* Divider */}
        <div className="w-full h-[1px] bg-[#222222] border border-[#222222]"></div>
      </div>
      {activeTab === "ranking" ? (
        <div className="flex flex-col space-y-4">
          <UserRanking />
        </div>
      ) : (
        <div className="flex justify-center gap-4 px-12 pt-10 ">
          <div className="flex flex-wrap gap-6 px-8 pt-6 pb-8 rounded-2xl ">
            <HistoryTab />
          </div>
        </div>
      )}
    </div>
  );
};

export default Ranking;
