import React, { useEffect, useState } from "react";
import { getAllHistory } from "./hooks/helper";
import { useQuery } from "@tanstack/react-query";
import { HistoryResponse } from "./hooks/types";
import { useWallet } from "./hooks/content";

export const useGetAllHistory = () => {
  const { pubAddress, setPubAddress, setConnectedWallet } = useWallet();
  const getHistory = async () => {
    const response = await getAllHistory(pubAddress || "");
    return response;
  };
  return useQuery({
    queryKey: ["getAllProtocols"],
    queryFn: () => getHistory(),
  });
};

const HistoryTab = () => {
  const [groupedHistory, setGroupedHistory] = useState<
    Record<string, { logo: string; transactions: HistoryResponse[] }>
  >({});
  const [loading, setLoading] = useState<boolean>(true);
  const { pubAddress } = useWallet();
  const [error, setError] = useState<string | null>(null);

  // const pubAddress = "0x4414d542b040c82fA44b6374704b40aee870281F";

  useEffect(() => {
    const fetchHistory = async () => {
      if (!pubAddress) {
        setError("Wallet address is not connected.");
        return;
      }
      try {
        const response = await getAllHistory(pubAddress);
        if (response) {
          const grouped = response.reduce((acc, item) => {
            const project = item?.__onboarding__.__project__;
            if (project?.title) {
              if (!acc[project.title]) {
                acc[project.title] = {
                  logo: project.logo,
                  transactions: [],
                };
              }
              acc[project.title].transactions.push(item);
            }
            return acc;
          }, {} as Record<string, { logo: string; transactions: HistoryResponse[] }>);
          setGroupedHistory(grouped);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
        setError("Failed to fetch history data.");
        setLoading(false);
      }
    };

    fetchHistory();
  }, [pubAddress]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (Object.keys(groupedHistory).length === 0) {
    return <p>No history available.</p>;
  }
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 ">
      {Object.entries(groupedHistory).map(
        ([projectTitle, { logo, transactions }]) => (
          <div
            key={projectTitle}
            className="flex flex-col gap-4 border border-[#666666] bg-[#1a1a1a] p-4 rounded-lg"
          >
            {/* Project Header */}
            <div className="flex items-center gap-2">
              <img
                src={logo}
                alt={`${projectTitle} Logo`}
                className="w-14 h-14 rounded-full"
              />
              <h3 className="font-bold text-[23px] text-white">
                {projectTitle}
              </h3>
            </div>

            {/* Transactions */}
            <div className="flex flex-col gap-2 w-[420px]">
              {transactions.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-[#121212] py-4 px-3 rounded-lg gap-4"
                >
                  <div className="flex items-center gap-2">
                    <div className="relative group inline-block">
                      <img
                        src={
                          item.__onboarding__.onboarding_type === "swapping"
                            ? "/assets/main_v4/swwap.svg"
                            : item.__onboarding__.onboarding_type === "lending"
                            ? "/assets/main_v4/lent.svg"
                            : item.__onboarding__.onboarding_type === "staking"
                            ? "/assets/main_v4/staked.svg"
                            : "/assets/main_v4/default-icon.svg"
                        }
                        alt="Swap Icon"
                        className="w-[32px] h-[32px] object-cover rounded-full"
                      />
                      <div className="absolute bottom-[110%] left-1/2 transform -translate-x-1/2 hidden group-hover:flex bg-black text-white text-[11px] p-2 rounded-[4px] border border-[#404040] w-[180px] justify-center items-center">
                        {item.type + " " + "onboarding app" || "N/A"}
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-[6px] border-transparent border-t-black"></div>
                      </div>
                    </div>
                    <img
                      src={
                        item?.__onboarding__?.asset_images.source_asset_icon ||
                        "/path/to/default-logo.png"
                      }
                      alt="Source Asset Logo"
                      className="w-[22px] h-[22px] rounded"
                    />
                    <img
                      src={
                        item?.__onboarding__?.asset_images.dest_asset_icon ||
                        "/path/to/default-logo.png"
                      }
                      alt="Destination Token Logo"
                      className="w-[22px] h-[22px] rounded"
                    />
                  </div>

                  {/* Center Section: Description */}
                  <div className="flex items-center p-2">
                    <p className="font-medium text-[15px] text-[#CCCCCC]">
                      Total{" "}
                      <span className="text-[#CCCCCC] font-medium text-[15px]">
                        {item.__onboarding__.onboarding_type === "swapping"
                          ? "Swapped"
                          : item.__onboarding__.onboarding_type === "lending"
                          ? "Lent"
                          : item.__onboarding__.onboarding_type === "staking"
                          ? "Staked"
                          : "Unknown"}
                      </span>
                      <span className="font-bold pl-2 text-[#FFFFFF]">
                        {item.amount || 0}
                      </span>
                    </p>
                    <div className="flex items-center gap-2 pl-1">
                      <img
                        src={
                          item?.__onboarding__?.asset_images.dest_asset_icon ||
                          "/path/to/default-logo.png"
                        }
                        alt="Destination Logo"
                        className="w-[22px] h-[20px]"
                      />
                      <span className="text-[#B882FF] font-medium text-[15px]">
                        {item?.__onboarding__?.asset_images.dest_asset_symbol ||
                          "Unknown"}
                      </span>
                    </div>
                  </div>

                  {/* Right Section */}
                  <div>
                    <span className="text-[#B882FF] font-medium text-[15px]">
                      {item.__onboarding__.onboarding_type
                        .charAt(0)
                        .toUpperCase() +
                        item.__onboarding__.onboarding_type.slice(1) ||
                        "Unknown"}
                      {/* {item.type
                        ? item.type.charAt(0).toUpperCase() + item.type.slice(1)
                        : "Unknown"} */}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default HistoryTab;
