import React, { useEffect, useState, useMemo } from "react";
import Card from "./Card";
import aporiLogo from "../../assets/V4/apori.svg";
import sideIllustration from "../../assets/V4/sidemask.svg";
import Ranking from "./Ranking";
import {
  AiFillThunderbolt,
  AiOutlineClose,
  AiOutlineArrowRight,
} from "react-icons/ai";
import MainHeader from "./MainHeader";
import { Link, useNavigate } from "react-router-dom";
import ExploreDApp from "./Exploredapp";
import PrevHeader from "./Header";
import { useWallet } from "./hooks/content";
import Tslogin from "./Tsconnect";
import { ExplorerTabs, walletType } from "./hooks/types";
import { Appplyreferral } from "./hooks/helper";
import StakeAndEarn from "./StakeAndEarn";
import TownesquareNFT from "./TownesquareNFT";

interface LoginButtonProps {
  title: string;
  logo: string;
  onClick?: () => void;
  roundedTop?: boolean;
  roundedBottom?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  title,
  logo,
  onClick,
  roundedTop,
  roundedBottom,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex h-[58px] items-center px-5 -mb-2 bg-[#FFFFFF08] border border-white/40 
      ${roundedTop ? "rounded-t-[8px]" : ""} 
      ${roundedBottom ? "rounded-b-[8px]" : ""}
      ${!roundedTop && !roundedBottom ? "rounded-[1px]" : ""} 
      justify-between w-[490px] hover:bg-[#FFFFFF1A]`}
    >
      <div className="flex items-center gap-2">
        <img src={logo} className="px-2" alt={title} />
        <p className="text-base font-medium text-white">{title}</p>
      </div>
      <div>
        <AiOutlineArrowRight size={20} color="#B882ff" />
      </div>
    </button>
  );
};

//LoginModal
export const ReferralPageModal: React.FC<{ onCancelProcess: () => void }> = ({
  onCancelProcess,
}) => {
  const [referralCode, setReferralCode] = useState<string[]>(Array(5).fill(""));
  const [error, setError] = useState<string>("");

  const handleInputChange = (value: string, index: number) => {
    const newCode = [...referralCode];
    if (/^[a-zA-Z0-9]?$/.test(value)) {
      newCode[index] = value.toUpperCase();
      setReferralCode(newCode);
      if (value && index < 4) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData
      .getData("Text")
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "");
    const newCode = Array(5).fill("");

    for (let i = 0; i < Math.min(pastedData.length, 5); i++) {
      newCode[i] = pastedData[i];
    }

    setReferralCode(newCode);

    // Focus the last filled input
    const lastFilledIndex = Math.min(pastedData.length, 5) - 1;
    const nextInput = document.getElementById(`input-${lastFilledIndex}`);
    nextInput?.focus();
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (!referralCode[index] && index > 0) {
        const prevInput = document.getElementById(`input-${index - 1}`);
        prevInput?.focus();
      }
    }
  };

  const isCodeComplete = referralCode.every((char) => char.length > 0);

  const handleReferralSubmit = async () => {
    if (!isCodeComplete) {
      setError("Please fill in all boxes.");
      return;
    }

    setError("");
    const fullReferralCode = referralCode.join("");

    try {
      const { success, message } = await Appplyreferral({
        referralcode: fullReferralCode,
      });

      if (success) {
        alert("Referral code applied successfully!");
        onCancelProcess();
      } else {
        setError(message);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      <div className="relative bg-[#222222] rounded-2xl px-8 w-[415px] h-[368px] flex flex-col items-center border border-[#FFFFFF66] ">
        <button className="absolute mt-5 right-8" onClick={onCancelProcess}>
          <img src="/assets/main_v4/close.svg" className="h-[24px] w-[24px]" />
        </button>
        <p className="text-center mt-5 p-4 font-Outfit text-[23px] text-white">
          Insert referral code <br /> to increase your ranking
        </p>
        <div className="flex gap-2 mt-8 mb-4">
          {referralCode.map((char, index) => (
            <input
              key={index}
              id={`input-${index}`}
              type="text"
              maxLength={1}
              value={char}
              onPaste={handlePaste}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 text-center text-lg bg-[#121212] text-white border border-[#555555] rounded-md focus:outline-none"
            />
          ))}
        </div>
        {error && <p className="mb-2 text-sm text-red-500">{error}</p>}
        <button
          onClick={handleReferralSubmit}
          disabled={!isCodeComplete}
          className={`mb-4 mt-4 w-[283px] h-[44px] rounded-4xl ${
            isCodeComplete
              ? "bg-[#9264F8] text-white"
              : "bg-[#6B549E] text-gray-300 cursor-not-allowed"
          }`}
        >
          Continue with referral code
        </button>
        <button
          onClick={onCancelProcess}
          className="text-[16px] font-medium leading-[20.16px] tracking-[0.02em] text-center text-[#B882FF]"
        >
          I don’t have a referral code
        </button>
      </div>
    </div>
  );
};

//EpochFlow Modal
export const EpochFlow: React.FC<{ onCancelProcess: () => void }> = ({
  onCancelProcess,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
      <div className="w-[794px] h-[394px]  border border-[#FFFFFF66] pt-7 px-12 pb-16 bg-[#222222] rounded-lg text-white">
        {/* Header */}
        <div className="flex items-center justify-between mb-10">
          <p className="text-[30px] font-bold">Epoch 1</p>
          <button onClick={onCancelProcess} className="p-1 hover:opacity-80">
            <AiOutlineClose size={22} color="#ffffff" />
          </button>
        </div>

        <p className="text-lg font-normal leading-[26.84px] mb-10">
          Perform actions on TowneSquare to improve your ranking
        </p>

        {/* Actions Grid */}
        <div className="flex items-center justify-between text-sm text-gray-300 space-x-18">
          {[
            {
              src: "/assets/main_v4/sswap.svg",
              text: "Swap tokens on any Onboarding app",
              class: "w-[102px] h-[85px]",
            },
            {
              src: "/assets/main_v4/lend.svg",
              text: "Lend tokens from any Onboarding app",
              class: "w-[85px] h-[96px]",
            },
            {
              src: "/assets/main_v4/stake.svg",
              text: "Stake tokens on any Onboarding app",
              class: "w-[100px] h-[85px]",
            },
          ].map((action, index) => (
            <div key={index} className="flex flex-col items-start space-x-2">
              <img
                src={action.src}
                alt={action.text}
                className={action.class}
              />
              <span className="text-[18px] font-normal block w-full leading-[24px] mt-2">
                {action.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OnboardPage = () => {
  const [isEpochnOpen, setisEpochnOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const navigate = useNavigate();
  const { pubAddress, setPubAddress, connectedWallet } = useWallet();
  const [activeTab, setActiveTab] = useState<ExplorerTabs | string>(
    "Explore apps"
  );

  const handleCloseModal = () => {
    setisEpochnOpen(false);
  };

  const handleOConnect = () => {
    setShowLoginModal(true);
  };
  const handleOfConnect = () => {
    setShowLoginModal(false);
  };

  const disconnectWallet = () => {
    setPubAddress(null);
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("pubAddress");
  };

  useEffect(() => {
    if (pubAddress) {
      setShowLoginModal(false);
    } else {
      setShowLoginModal(false);
    }
  }, [pubAddress]);

  useMemo(() => {
    const activeTab = sessionStorage.getItem("ExplorerTab");
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, []);

  return (
    <div className="flex min-h-screen text-white bg-black">
      {/* Sidebar */}
      <aside className="w-[240px] z-30 fixed h-screen bg-[#121212] p-6 hidden px-10 md:flex flex-col justify-between">
        <div>
          <div className="flex items-center mt-6 w-[166px]">
            <Link to="/">
              <img src="/assets/main_v4/theloggo.svg" alt="Townesquare logo" />
            </Link>
          </div>
          <img src="/assets/main_v4/sidemask.svg" alt="Townesquare sidemask" />
          <div className="mb-24">
            <button
              onClick={() => setisEpochnOpen(!isEpochnOpen)}
              className="flex items-center justify-between w-[126px] h-[44px] px-8 py-6  -mt-6 gap-2 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
            >
              <span className="text-white text-[15px]">Epoch</span>
              <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px]  pr-2 pl-2">
                1
              </span>
            </button>
          </div>
          <nav>
            <ul className="flex flex-col gap-[32px] text-sm font-bold">
              <li
                className={`cursor-pointer text-[16px] ${
                  activeTab === "Ranking" ? "text-purple-400" : ""
                }`}
                onClick={() => {
                  setActiveTab("Ranking");
                  sessionStorage.setItem("ExplorerTab", "Ranking");
                }}
              >
                Ranking
              </li>
              <li
                className={`cursor-pointer text-[16px] ${
                  activeTab === "Explore apps" ? "text-purple-400" : ""
                }`}
                onClick={() => {
                  setActiveTab("Explore apps");
                  sessionStorage.setItem("ExplorerTab", "Explore apps");
                }}
              >
                Explore apps
              </li>

              {/* <li
                className={`cursor-pointer text-[16px] ${
                  activeTab === "Stake & Earn" ? "text-purple-400" : ""
                }`}
                onClick={() => {
                  setActiveTab("Stake & Earn");
                  sessionStorage.setItem("ExplorerTab", "Stake & Earn");
                }}
              >
                Stake & Earn
              </li>

              <li
                className={`cursor-pointer text-[16px] ${
                  activeTab === "Townsquare NFT" ? "text-purple-400" : ""
                }`}
                onClick={() => {
                  setActiveTab("Townsquare NFT");
                  sessionStorage.setItem("ExplorerTab", "Townsquare NFT");
                }}
              >
                Townsquare NFT
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
      {/* Main Content */}
      <main className="flex-1 ml-[240px] min-h-screen  w-[calc(100vw-240px)]">
        {activeTab === "Explore apps" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <ExploreDApp />
          </div>
        )}
        {activeTab == "Ranking" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <Ranking />
          </div>
        )}
        {/* {activeTab == "Stake & Earn" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div
              className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"
              style={{
                backgroundImage: "url(/assets/main_v4/StakeAndEarnBg.svg)",
                backgroundSize: "contain",
              }}
            ></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <StakeAndEarn onConnect={handleOConnect} />
          </div>
        )}

        {activeTab == "Townsquare NFT" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div
              className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"
              
            ></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <TownesquareNFT onConnect={handleOConnect} />
          </div>
        )} */}
      </main>
      {showLoginModal && <Tslogin onCancelProcess={handleOfConnect} />}
      {isEpochnOpen && <EpochFlow onCancelProcess={handleCloseModal} />}
    </div>
  );
};

export default OnboardPage;
