import { useState, useEffect, useMemo, useRef } from "react";
import { throttle } from "lodash";
import aporiLogo from "../../assets/V4/apori.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import Card from "./Card";
import ExploreDApp from "./Exploredapp";
import ExploreDAppCard from "./ExloreCard";
import ExploreViewCard from "./ExloreCard";
import ExploreCard from "./ExloreCard";
import OnboardingCard from "./OnboardingCard";

gsap.registerPlugin(ScrollTrigger);

const features = [
  {
    title: "Abstracted cross-dapp experiences",
    description:
      "Experience crypto with no more chains, straight from dapp to dapp.",
    w: 436,
  },
  {
    title: "Customizable onboarding flow creation",
    description:
      "Embed multiple actions from DEX, bridge, etc. in your onboarding channel",
    w: 599,
  },
  {
    title: "Sharable anytime anywhere",
    description:
      "Generate an onboarding action link for your onboarding app for anyone, anywhere",
    w: 523,
  },
  {
    title: "Support multiple wallet onboard options",
    description: "Meet your users with both Web3 & Web2 signing options",
    w: 678,
  },
];

function SectionHeader({
  sectionName,
  title,
  subTitle,
}: {
  sectionName: string;
  title?: string;
  subTitle?: string;
}) {
  return (
    <div className="flex flex-col w-full px-5 md:items-center gap-y-3 font-Outfit md:px-14 xl:px-0 xl:w-section-header">
      <span className="text-ssm md:text-xl font-bold leading-tight tracking-[.2em] text-[#00EEFD]">
        {sectionName}
      </span>
      <span className="font-medium text-2.5xl md:text-4.5xl mb-2 leading-tight md:text-center ">
        {title}
      </span>
      {subTitle && (
        <span className="text-lg md:text-2xl leading-tight md:text-center md:w-[600px] xl:w-auto text-gray-light">
          {subTitle}
        </span>
      )}
    </div>
  );
}

interface TestimonialCardProps {
  headerImage: string;
  content: string;
  link: string;
}
export const TestimonialCard: React.FC<TestimonialCardProps> = ({
  headerImage,
  content,
  link,
}) => {
  return (
    <div
      onClick={() => window.open(link, "_blank")}
      className="relative z-10 w-[296px] h-[388px] py-6 px-5 bg-[#121212] hover:bg-[#1c1c1c] border border-[#404040] rounded-[16px] flex flex-col   cursor-pointer"
    >
      <div className="flex items-center gap-4">
        <img src={headerImage} alt="Testimonial" className="w-full" />
      </div>

      <p className="text-white text-[15px] leading-[20.4px] font-outfit mt-4">
        {content}
      </p>

      <a
        href={link}
        className="absolute mt-4 text-primary-light bottom-6 left-5"
        target="_blank"
        rel="noopener noreferrer"
      >
        See post on X
      </a>
    </div>
  );
};

function Para({
  title,
  content,
  classes,
  titleClasses,
}: {
  title?: string;
  content?: string;
  classes?: string;
  titleClasses?: string;
}) {
  return (
    <div
      className={`flex flex-col gap-y-2 px-4 md:px-0 font-Outfit ${
        classes || ""
      }`}
    >
      <span
        className={`text-[28px] md:text-2.5xl font-semibold leading-tight ${
          titleClasses || ""
        }`}
        dangerouslySetInnerHTML={title ? { __html: title } : undefined}
      />
      <span className="leading-tight text-[20px] md:text-xl md:text-gray-light">
        {content}
      </span>
    </div>
  );
}

function FlatButton({
  text,
  imgSrc,
  url,
}: {
  text: string;
  imgSrc: string;
  url: string;
}) {
  return (
    <div className="flex items-center justify-center w-[230px] h-[60px] border-2  border-solid border cursor-pointer md:w-60 rounded-xl bg-primary-dark2 border-gray-light3 ">
      <a
        className="flex items-center justify-center gap-x-2"
        href={url}
        target="_blank"
      >
        <img className="w-6 h-auto md:w-auto md:h-7" src={imgSrc} alt="icon" />
        <span className="text-base text-[18px] font-medium text-white md:text-[18px] font-Outfit">
          {text}
        </span>
      </a>
    </div>
  );
}

type LINK = {
  text: string;
  link?: string;
  onClick?: () => void;
};
function Links({ title, links }: { title: string; links: LINK[] }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col text-sm font-Outfit gap-y-2">
      {/* Title */}
      <span className="font-semibold text-white">{title}</span>
      {links.map((item, i) => {
        if (item.onClick) {
          return (
            <button
              key={i}
              onClick={item.onClick}
              className="text-left bg-transparent border-none cursor-pointer text-gray-light2 hover:underline"
            >
              {item.text}
            </button>
          );
        } else if (item.link) {
          return (
            <a
              key={i}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-light2 hover:underline"
            >
              {item.text}
            </a>
          );
        } else {
          // Render as plain text if neither `link` nor `onClick` is provided
          return (
            <span key={i} className="text-gray-light2">
              {item.text}
            </span>
          );
        }
      })}
    </div>
  );
}

function XIcon() {
  return (
    <a href="https://x.com/TowneSquarexyz" target="_blank">
      <img
        src="/assets/main_v3/x.png"
        className="w-5 h-auto md:w-6"
        alt="logo"
      />
    </a>
  );
}

function DiscordIcon() {
  return (
    <a href="https://discord.gg/yMRmqFzrDW" target="_blank">
      <img
        src="/assets/main_v3/discord.png"
        className="w-6 h-auto md:w-[30px]"
        alt="logo"
      />
    </a>
  );
}

function FooterFollow() {
  return (
    <div className="flex flex-col gap-y-2">
      <span className="text-sm font-semibold">Follow us</span>
      <div className="flex items-center gap-x-4">
        <XIcon />
        <DiscordIcon />
      </div>
    </div>
  );
}

function FooterCopyright() {
  return (
    <a
      className="text-sm md:text-base"
      href=" "
      target="_blank"
      rel="noreferrer"
    >
      Copyright © 2025 TowneSquare. All rights reserved.
    </a>
  );
}

function MainV3() {
  const [activeFeature, setActiveFeature] = useState(0);
  const [activeSection, setActiveSection] = useState("");
  const navigate = useNavigate();

  const links = [
    {
      title: "Legal",
      links: [
        {
          text: "Terms of Service",
          link: "https://x.com/TowneSquarexyz",
        },

        {
          text: "Privacy Policy",
          onClick: () => navigate("Privacy"),
        },
      ],
    },
    {
      title: "Community",
      links: [
        {
          text: "X",
          link: "https://x.com/TowneSquarexyz",
        },
        {
          text: "Discord",
          link: "https://discord.gg/yMRmqFzrDW",
        },
      ],
    },
  ];

  function FooterLinks() {
    return (
      <>
        {links.map((item, i) => (
          <Links key={i} title={item.title} links={item.links} />
        ))}
      </>
    );
  }

  const main = useRef(null);
  const cardsRef = useRef(null);

  const isTabletOrAbove = useMediaQuery({ query: "(min-width: 768px)" });
  const cardHeight = isTabletOrAbove ? 112 : 80;

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["features", "onboarding-links", "dapp-bastraction"];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      setActiveSection(currentSection || "");
    };

    const throttledHandleScroll = throttle(handleScroll, 200);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useMemo(() => {
    ScrollTrigger.create({
      trigger: "#cards",
      start: 0,
      end: 200,
      onUpdate: (self) => {
        if (cardsRef.current) {
          const cards = gsap.utils.toArray(".card");
          cards.forEach((card, i) => {
            // only change marginTop of 2nd to 4th elements
            if (i === 0) {
              return;
            }
            // change style works, change className not
            (card as HTMLElement).style.marginTop = `-${Math.floor(
              cardHeight + self.progress * cardHeight
            )}px`;
          });
        }
      },
    });
  }, []);

  return (
    // page wrapper
    <div className="relative flex flex-col overflow-hidden bg-dark-lg">
      <div className="fixed z-0 pointer-events-none">
        <img
          className="h-auto w-bg-lines"
          src="/assets/main_v3/bg-lines.png"
          alt="bg-line"
        />
      </div>
      {/* header */}
      <div className="fixed flex justify-center w-full px-3 mt-6 md:px-4 xl:px-0 md:mt-header z-max">
        <div className="flex items-center justify-between w-full px-4 md:px-8 xl:px-16 xl:w-header_xl h-header md:h-header_md rounded-header bg-header-bg">
          <img
            src="/assets/main_v3/ts-logo.png"
            className="w-[169px] md:w-[154px] xl:w-[220px] h-auto"
            alt="logo"
          />

          {/* hide on mobile */}
          <div className="items-center hidden text-center md:flex md:gap-x-6 xl:gap-x-14 font-Outfit">
            <button
              // href="#features"
              onClick={() => {
                navigate("onboard");
                sessionStorage.setItem("ExplorerTab", "Explore apps");
              }}
              className={`${
                activeSection === "features" ? "text-white" : "text-gray-light2"
              }`}
            >
              Onboarding apps
            </button>

            <a
              // href="#onboarding-links"
              href="https://dashboard.townesquare.xyz/modular"
              className={`${
                activeSection === "onboarding-links"
                  ? "text-white"
                  : "text-gray-light2"
              }`}
            >
              Modular features library
            </a>
            <a
              // href="#dapp-bastraction"
              href="https://dashboard.townesquare.xyz/"
              className={`${
                activeSection === "dapp-bastraction"
                  ? "text-white  bg-[#9264F8] w-[146px] h-[40px]  hover:bg-[#9F71FF] flex items-center justify-center p-[12px] rounded-[32px] px-4"
                  : "text-white  bg-[#9264F8] w-[146px] h-[40px]   hover:bg-[#9F71FF] flex items-center justify-center p-[12px] rounded-[32px] px-4"
              }`}
            >
              Start building
            </a>
          </div>
          <div className="flex items-center gap-x-6">
            <XIcon />
            <DiscordIcon />
          </div>
        </div>
      </div>
      {/* section banner */}
      <div
        ref={main}
        className="flex flex-col items-center w-full pb-8 bg-cover md:pb-20 xl:pb-40 pt-36 md:pt-50 bg-index-s1-bg"
      >
        {/* content on the left and right */}
        <div className="flex flex-col items-center xl:flex-row gap-y-12 md:gap-y-24 xl:gap-x-20">
          {/* slogon */}
          <div className="flex flex-col px-5 xl:w-180 gap-y-4 md:gap-y-4 md:px-15 xl:px-0">
            <span className="text-2.5xl md:text-[43px] font-semibold leading-tight font-Outfit">
              <span className="relative inline-block text-primary-light">
                The 1st Cross-
                <span className="relative inline-block">
                  Chain
                  <span className="absolute left-0 top-1/2 w-full h-[5px] bg-current transform -translate-y-1 rotate-[13deg]"></span>
                </span>
               {" "} Dapp
              </span>{" "}
              Interoperability Protocol
            </span>

            <span className="text-lg leading-normal tracking-wide md:text-2xl text-gray-light font-Outfit mb-[60px]">
              Bringing interoperability directly to the users on the <br />
              dapp level - go from asset to action in just several clicks.
            </span>
            <span className="flex items-center pb-1 -mb-2 text-base font-semibold leading-normal gap-x-1 text-gray-light">
              BUILT WITH
              <img
                className="w-auto h-[24px] ml-2"
                src="/assets/main_v4/powerlogo.svg"
                alt="mona"
              />
            </span>
            <span className="flex items-center text-base font-semibold mt-[8px] mr-1 lg:mt-0 leading-normal gap-x-1 text-gray-light ">
              POWERED BY
              <img
                className="w-auto h-5"
                src="/assets/main_v4/pwmonad.svg"
                alt="mona"
              />
            </span>

            <div className="flex flex-col mt-8 text-white md:flex-row md:items-center md:mt-25 xl:mt-10 gap-y-5 font-Outfit">
              <button
                onClick={() =>
                  window.open("https://dashboard.townesquare.xyz/", "_blank")
                }
                className="flex items-center justify-center z-10  h-12 text-sm font-medium w-[233px] md:w-[190px] rounded-4xl text-white bg-[#9264F8]  hover:bg-[#9F71FF]"
              >
                Start Building
              </button>

              <button
                onClick={() => {
                  navigate("onboard");
                  sessionStorage.setItem("ExplorerTab", "Explore apps");
                }}
                className="flex items-center justify-center text-black h-12 text-sm font-medium w-[224px] md:w-[230px] md:ml-4 rounded-4xl bg-[#FFFFFF] z-10 relative"
              >
                Explore onboarding apps
              </button>
            </div>
          </div>

          {/* mobile only */}
          <img
            src="/assets/main_v4/mobilehero.svg"
            className="block w-auto h-auto md:hidden"
            alt="Mobile Hero"
          />
          <div className="hidden md:block">
            <div id="cards" ref={cardsRef} className={`flex flex-col`}>
              <img
                className="z-50 h-auto w-grad-card md:w-grad-card_xl card"
                src="/assets/main_v3/hero-1.png"
                alt="hero"
              />
              <img
                className="z-40 h-auto w-grad-card md:w-grad-card_xl card"
                style={{ marginTop: `-${cardHeight}px` }}
                src="/assets/main_v3/hero-2.png"
                alt="hero"
              />
              <img
                className="z-30 h-auto w-grad-card md:w-grad-card_xl card"
                style={{ marginTop: `-${cardHeight}px` }}
                src="/assets/main_v3/hero-3.png"
                alt="hero"
              />
              <img
                className="z-20 h-auto w-grad-card md:w-grad-card_xl card"
                style={{ marginTop: `-${cardHeight}px` }}
                src="/assets/main_v3/hero-4.png"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>

      {/* //NEW FLOW */}

      <div className="flex flex-col w-full px-5 pt-6 mx-auto overflow-hidden md:items-center gap-y-3 font-Outfit md:px-14 xl:px-0 xl:w-section-header">
        <span className="text-ssm md:text-xl font-bold leading-tight tracking-[.2em] text-[#00EEFD]">
          ONBOARDING APPS
        </span>
        <span className="font-medium text-2.5xl md:text-4.5xl mb-12 leading-tight md:text-center">
          Explore all DeFi opportunities via abstracted onboarding apps
        </span>
      </div>

      <div className="relative flex flex-col items-center overflow-hidden">
        {/* Gradient Left */}
        <div className="absolute top-0 left-0 z-10 h-full pointer-events-none lg:w-72 lg:bg-gradient-to-r lg:from-dark-lg lg:to-transparent"></div>

        <div className="relative flex flex-row gap-6 mb-12 overflow-x-hidden">
          {/* Primary Content */}
          <div className="flex flex-row gap-6 animate-loop-scroll">
            <OnboardingCard />
          </div>

          {/* Duplicate Content */}
          <div
            className="absolute top-0 flex flex-row gap-6 animate-loop-scroll left-full"
            style={{ left: `calc(100% + 24px)` }}
          >
            <OnboardingCard />
          </div>
        </div>
        {/* Gradient Right */}
        <div className="absolute top-0 right-0 z-10 h-full pointer-events-none w-72 lg:bg-gradient-to-l lg:from-dark-lg lg:to-transparent">
          {" "}
        </div>
      </div>

      <button
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("onboard");
        }}
        className="flex items-center justify-center h-12 text-sm font-medium w-[190px] mb-24 mt-6 rounded-4xl text-white bg-[#9264F8] mx-auto z-10"
      >
        See all onboarding apps
      </button>

      {/* next section */}
      <div className="flex lg:mx-auto">
        <SectionHeader sectionName="ECOSYSTEM" />
      </div>
      <span className="text-[28px] md:text-[43px] font-[Outfit] mb-6 font-semibold leading-[34.16px] md:leading-[52.46px] md:text-center text-white px-5  pb-8 md:pt-0 md:pb-0 -mt-3 md:mb-[42px]">
        Supporting <span className="text-[#9264F8] "> cross-dapp</span>{" "}
        experiences from
      </span>

      <div className="relative flex items-center justify-center ">
        {/* Gradient Left */}
        <div className="absolute top-0 left-0 z-10 block h-full md:hidden w-18 bg-gradient-to-r from-dark-lg to-transparent"></div>

        <div className="hidden md:flex flex-row items-center  pr-6 h-[36px] md:h-[67px] mb-12 space-x-[68px] overflow-hidden">
          <img
            className="h-[29px] "
            onClick={() => window.open("https://www.monad.xyz/", "_blank")}
            src="/assets/main_v4/mmonad.svg"
            alt="support chain"
          />
          {/* <img
            className="h-[75px]"
            onClick={() => window.open("https://www.berachain.com/", "_blank")}
            src="/assets/main_v4/Berachain.svg"
            alt="support chain"
          /> */}
          <img
            className="h-[29px]"
            onClick={() => window.open(" ", "_blank")}
            src="/assets/main_v4/story.svg"
            alt="support chain"
          />
          <img
            className="h-[29px]"
            onClick={() =>
              window.open("https://aptosfoundation.org/", "_blank")
            }
            src="/assets/main_v4/apttos.svg"
            alt="support chain"
          />

          <img
            className="h-[44px]"
            onClick={() => window.open("https://ethereum.org/", "_blank")}
            src="/assets/main_v4/Ethe.svg"
            alt="support chain"
          />
          <img
            className="h-[23px]"
            onClick={() => window.open("https://solana.com/", "_blank")}
            src="/assets/main_v4/solana.svg"
            alt="support chain"
          />
          <img
            className="h-[52px]"
            onClick={() => window.open("https://arbitrum.io/", "_blank")}
            src="/assets/main_v4/Arbit.svg"
            alt="support chain"
          />
          <img
            className="h-[33px]"
            onClick={() => window.open("https://www.base.org/", "_blank")}
            src="/assets/main_v4/bas.svg"
            alt="support chain"
          />
        </div>
        <div className="md:hidden flex flex-row items-center  pr-6 h-[36px] md:h-[67px] mb-12 space-x-12 animate-loop-scrol-4">
          <img
            className="h-[29px] "
            onClick={() => window.open("https://www.monad.xyz/", "_blank")}
            src="/assets/main_v4/mmonad.svg"
            alt="support chain"
          />
          {/* <img
            className="h-[75px]"
            onClick={() => window.open("https://www.berachain.com/", "_blank")}
            src="/assets/main_v4/Berachain.svg"
            alt="support chain"
          /> */}
          <img
            className="h-[29px]"
            onClick={() => window.open(" ", "_blank")}
            src="/assets/main_v4/story.svg"
            alt="support chain"
          />
          <img
            className="h-[29px]"
            onClick={() =>
              window.open("https://aptosfoundation.org/", "_blank")
            }
            src="/assets/main_v4/apttos.svg"
            alt="support chain"
          />

          <img
            className="h-[44px]"
            onClick={() => window.open("https://ethereum.org/", "_blank")}
            src="/assets/main_v4/Ethe.svg"
            alt="support chain"
          />
          <img
            className="h-[23px]"
            onClick={() => window.open("https://solana.com/", "_blank")}
            src="/assets/main_v4/solana.svg"
            alt="support chain"
          />
          <img
            className="h-[52px]"
            onClick={() => window.open("https://arbitrum.io/", "_blank")}
            src="/assets/main_v4/Arbit.svg"
            alt="support chain"
          />
          <img
            className="h-[33px z-[10] opacity-1]"
            onClick={() => window.open("https://www.base.org/", "_blank")}
            src="/assets/main_v4/bas.svg"
            alt="support chain"
          />
        </div>
      </div>
      <div className="z-10 flex flex-col items-center w-full px-6 pt-[150px] pb-[150px]  pb-8 md:pb-[300px] md:pt-[300px] md:px-6 xl:px-0">
        <div className="flex flex-col-reverse items-center justify-center gap-y-6 md:flex-row md:gap-x-8 xl:gap-x-[60px]">
          <span className="text-[20px] leading-[30px] sm:text-[32px] sm:leading-[57px] text-white font-bold text-center md:text-left">
            <span className=" text-[#B882FF]"> “ </span>
            When the blockchain <br className="hidden lg:block" />
            throughput issue is solved, <br /> the next frontier of crypto{" "}
            <br /> experience is dapp experiences
            <span className=" text-[#B882FF]"> ”</span>
          </span>
          <img
            className="w-[600px] px-4 md:w-[310px]h-auto"
            src="assets/main_v4/tsgroup.svg"
            alt="link"
          />
        </div>
      </div>

      {/* section features */}
      <div
        className="z-10 flex flex-col items-center w-full px-5 pt-16 pb-6 xl:pt-40 bg-dark-base/30 md:pb-20 xl:pb-24 rounded-t-section md:rounded-t-section_md"
        id="features"
      >
        <SectionHeader
          sectionName="DAPP ABSTRACTION"
          title="Pioneering DApp Interoperability"
          subTitle="TowneSquare is building the interoperable dapp stack to power crypto experiences"
        />
        {/*         
        <div className="flex flex-col w-full md:items-center gap-y-3 font-Outfit md:px-14 xl:px-0 xl:w-section-header">
          <span className="hidden md:block text-ssm md:text-xl font-bold leading-tight tracking-[.2em] text-primary-light">
            BUILD POWERFUL ONBOARDING APPS
          </span>
          <span className="md:hidden text-ssm md:text-xl font-bold leading-tight tracking-[.2em] text-primary-light">
            FEATURES
          </span>
          <span className="font-medium text-2.5xl  md:text-4.2xl leading-tight md:text-center">
            Embed however many onboarding actions in one singular onboarding
            flow<span className="hidden md:inline">, powered by</span>
            <span className="text-[#B882FF] text-2.5xl  md:text-4.2xl hidden md:inline">
              {" "}
              dApp modularity{" "}
            </span>
          </span>
        </div> */}

        <div className="flex flex-col items-center mt-16 md:mt-32 md:flex-row md:px-16 xl:px-0 md:justify-between xl:justify-normal gap-x-20">
          {/* feature list on the left */}
          <div className="flex flex-col gap-y-16 md:gap-y-0 md:border-l xl:w-feature-list_xl md:w-feature-list_md md:border-l-gray-base">
            {features.map((feature, i) => (
              // feature item
              <div className="flex flex-col gap-y-8" key={i}>
                <div
                  className={`flex flex-col gap-y-1.5 pl-4 md:pl-8 md:-ml-px cursor-pointer border-l-4 border-primary-light
                  ${
                    i === activeFeature
                      ? "md:py-7 md:border-l-4 md:border-primary-light"
                      : "md:py-11 md:border-none"
                  }
                `}
                  onClick={() => setActiveFeature(i)}
                >
                  <span
                    className={`text-xl xl:text-2xl text-primary-light font-semibold
                    ${
                      i === activeFeature
                        ? "md:text-primary-light md:font-semibold"
                        : "md:text-gray-light"
                    }
                  `}
                  >
                    {feature.title}
                  </span>
                  <span
                    className={`text-base md:text-lg xl:text-xl md:leading-tight ${
                      i !== activeFeature && "md:hidden"
                    }`}
                  >
                    {feature.description}
                  </span>
                </div>
                <img
                  className="w-full h-auto px-8 md:hidden "
                  src={`/assets/main_v3/feature-${i + 1}.png`}
                  alt="feature-img"
                />
              </div>
            ))}
          </div>
          {/* feature  on tablet and desktop */}
          <div className="hidden md:block md:w-80 md:h-80 xl:w-120 xl:h-120">
            <img
              src={`/assets/main_v3/feature-${activeFeature + 1}.png`}
              alt="feature-img"
              className="object-contain max-w-full max-h-full"
            />
          </div>
        </div>
        <button 
         onClick={() =>
          window.open("https://dashboard.townesquare.xyz/", "_blank")
        }
        className="flex items-center justify-center h-12 text-sm font-medium w-[190px] mt-12 rounded-4xl text-white bg-[#9264F8]">
          Start Building
        </button>
      </div>
      {/* section links */}
      <div
        className="z-20 flex flex-col items-center w-full px-5 pt-12 pb-20 mt-16 mb-6 md:px-0 md:pt-2 rounded-b-section"
        id="onboarding-links"
      >
        <SectionHeader sectionName="BUILD POWERFUL ONBOARDING APPS" />
        <span className="font-medium text-2.5xl  md:text-4.2xl leading-tight md:text-center">
          Embed however many onboarding actions in one singular <br />{" "}
          onboarding flow
          <span className="hidden md:inline">, powered by</span>
          <span className="text-[#B882FF] text-2.5xl  md:text-4.2xl hidden md:inline">
            {" "}
            dApp modularity{" "}
          </span>
        </span>

        <div className="flex flex-col w-full mt-14 md:mt-20 xl:flex-row md:w-120 xl:w-auto gap-x-24 gap-y-18 md:gap-y-25 xl:gap-y-0">
          <div className="flex flex-col-reverse items-center md:flex-col gap-y-8">
            <img
              className="w-auto h-[210px]"
              src="/assets/main_v3/dapp-1.png"
              alt="dapp"
            />
            <Para
              title="Across staking, swapping, <br /> bridging & lending"
              content="Where existing aggregator apps cover only   swap and bridge, our abstraction stack covers all DeFi use cases."
              classes="px-0 pl-4 flex gap-x-4 border-l-4 border-primary-light md:border-none xl:w-120"
              titleClasses="text-primary-light md:text-white"
            />
          </div>
          <div className="flex flex-col-reverse items-center md:flex-col gap-y-8">
            <img
              className="w-auto h-[210px]"
              src="/assets/main_v3/dapp-2.png"
              alt="dapp"
            />
            <Para
              title="No additional smart contract <br />  risks"
              content="Each feature is a primitive abstracted from a Dapp into a library, with no additional smart contract involved."
              classes="px-0 pl-4 flex gap-x-4 border-l-4 border-primary-light md:border-none xl:w-120"
              titleClasses="text-primary-light md:text-white"
            />
          </div>
        </div>
        <div className="flex flex-col items-center mt-20 text-white md:mt-26 xl:mt-14 gap-y-3 font-Outfit">
          <button
            onClick={() =>
              window.open("https://dashboard.townesquare.xyz/modular", "_blank")
            }
            className="flex items-center justify-center h-12 text-sm font-medium w-button rounded-4xl text-white bg-[#9264F8]  hover:bg-[#9F71FF]"
          >
            Browse Modular features library
          </button>
        </div>
      </div>

      {/* section links */}
      <div
        className="z-10 flex flex-col items-center w-full px-5 pt-12 pb-8 md:pb-8 md:pt-44 md:px-7 xl:px-0 bg-dark-base/30"
        id="dapp-bastraction"
      >
        <SectionHeader
          sectionName="A NEW CONSUMER ONBOARDING STANDARD"
          title="Onboarding Links to bring users from anywhere to your DApp"
          subTitle="Generate your onboarding links that contain onboarding actions for your app"
        />
        <div className="flex flex-col items-center mt-16 gap-y-18 font-Outfit">
          <div className="flex flex-col items-center gap-y-6 md:flex-row md:gap-x-8 xl:gap-x-14">
            <img
              className="w-full md:w-[306px] xl:w-[470px] h-auto"
              src="/assets/main_v3/link-1.png"
              alt="link"
            />
            <Para
              title="Customizable actions"
              content="Each link contains customizable modular features to onboard users from any setting with a series of actions, all in one flow."
              classes="md:w-[343px]"
            />
          </div>
          <div className="flex flex-col-reverse items-center gap-y-6 md:flex-row md:gap-x-8 xl:gap-x-14">
            <Para
              title="Sharable anywhere"
              content="Share them easily on social media and other platforms, both Web2 and Web3"
              classes="md:w-[314px] xl:w-[343px]"
            />
            <img
              className="w-full px-4 md:w-[310px] xl:w-[406px] h-auto"
              src="/assets/main_v3/link-2.png"
              alt="link"
            />
          </div>
        </div>
        <button
          onClick={() =>
            window.open("https://dashboard.townesquare.xyz", "_blank")
          }
          className="flex items-center justify-center mb-16 mt-16 h-12 text-sm font-medium w-[190px] rounded-4xl text-white bg-[#9264F8]  hover:bg-[#9F71FF] "
        >
          Start Building
        </button>
      </div>
      {/* section dapp */}

      {/* section social */}
      <div className="relative z-10 flex flex-col items-center w-full px-5 py-20 pt-10 md:px-0 md:pt-44 md:pb-40">
        <span className="mb-8 text-lg leading-tight md:text-2xl md:w-150 text-gray-light md:text-white md:text-center xl:w-auto">
          What the community says about TownSquare
        </span>

        <div className="flex w-full overflow-x-auto lg:justify-center hide-scrollbar">
          <div className="flex gap-6 ">
            <TestimonialCard
              headerImage={"/assets/main_v4/testmonia1.svg"}
              content={
                "TowneSquare is enabling Dapp Abstraction to meaningfully accelerate the onboarding process for all apps in the Monad ecosystem. Their side-by-side video showing onboarding with and without Townesquare was night and day. In addition to the laser-sharp vision, I liked the team's thoughtfulness and hustle."
              }
              link={"https://x.com/keoneHD/status/1842390642149753282"}
            />
            <TestimonialCard
              headerImage={"/assets/main_v4/testmonia2.svg"}
              content={
                "TowneSquare is solving crypto's biggest problem — user experience. By bringing the action directly to where they are (twitter, duh) they allow anyone to move their assets with just a few clicks. Very excited to see what the 2nd and 3rd order implications of TowneSquare are! 🙏."
              }
              link={"https://x.com/intern?s=21&t=Ev8iG2Y2juJ5fGAhNTZ2yQ"}
            />
            <TestimonialCard
              headerImage={"/assets/main_v4/testmonia1.svg"}
              content={
                "TowneSquare rocked Monad Madness with an awesome demo of accelerating app onboarding with Dapp Abstraction. Excited to follow their rapid progress"
              }
              link={"https://x.com/keoneHD/status/1851659955067093084"}
            />
          </div>
        </div>

        <img
          className="absolute w-full md:w-[240%] md:max-w-none xl:w-full h-auto left-0 bottom-0 "
          src="/assets/main_v3/bg-curve-bottom.png"
          alt="bg"
        />
        <div className="z-20 flex flex-col justify-start   md:items-center md:px-0 gap-y-4 md:gap-y-2 font-Outfit mt-[100px]">
          <span className="text-2.5xl md:text-4.5xl font-medium leading-tight">
            Find us on socials
          </span>
          <span className="text-lg leading-tight md:text-2xl md:w-150 text-gray-light md:text-white md:text-center xl:w-auto">
            TownSquare is using Dapp abstraction to power onboarding experiences
          </span>
        </div>
        <div className="z-20 flex flex-col items-center w-full mt-8 md:mt-20 md:flex-row gap-y-4 md:gap-x-9 md:w-auto">
          <FlatButton
            text="@TowneSquarexyz"
            imgSrc="/assets/main_v3/x.png"
            url="https://x.com/TowneSquarexyz"
          />
          <FlatButton
            text="TownSquare"
            imgSrc="/assets/main_v3/discord.png"
            url="https://discord.gg/yMRmqFzrDW"
          />
        </div>
      </div>
      {/* footer above mobile */}
      <div className="z-20 justify-between hidden w-full px-8 py-8 md:flex xl:px-40 bg-primary-dark3">
        {/* content */}
        <div className="flex justify-between w-full">
          {/* content on the left */}
          <div className="flex flex-col justify-between gap-[35px]">
            <FooterFollow />
            <FooterCopyright />
          </div>
          {/* content on the right */}
          <div className="flex gap-x-24">
            <FooterLinks />
          </div>
        </div>
      </div>
      {/* footer on mobile */}
      <div className="z-20 flex flex-col w-full px-5 py-8 md:hidden gap-y-12 bg-primary-dark3">
        <img
          src="/assets/main_v3/ts-logo.png"
          className="w-40 h-auto"
          alt="logo"
        />
        <div className="flex justify-between">
          <FooterLinks />
          <FooterFollow />
        </div>
        <FooterCopyright />
      </div>
    </div>
  );
}

export default MainV3;
