import React, { useEffect, useState } from "react";
import { AiFillThunderbolt } from "react-icons/ai";
import { useGetAllOnboardingApps, useGetAllOnboardingLinks, useGetAllProtocols } from "./hooks/helper";
import { useParams } from "react-router-dom";

const OnboardingCard = () => {
  
  const onboardingAppsData = useGetAllOnboardingLinks();
  const onboardingApps = onboardingAppsData.data || [];

  return (
    
       <div className="py-4 text-white">
       <div className="flex gap-4">
          {onboardingApps.map((app, index) => (
            <div
              key={index}
              className="p-6 w-[290px] flex flex-col h-[342px] bg-[#222222] border-[#666666] border rounded-lg"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={app.__project__?.logo}
                    alt={app.__project__?.title}
                    className="w-[24px] h-[24px] rounded-full border border-white/40 object-cover"
                  />
                  <p className="text-xl ml-2">
                    {app.onboarding_type.charAt(0).toUpperCase() +
                      app.onboarding_type.slice(1)}{" "}
                     
                  </p>
                </div>

                {app.bonus_percentage !== null && app.bonus_percentage > 0 && (
                  <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
                    <div className="flex items-center font-bold">
                      <AiFillThunderbolt size={12} color="#00EEFD" />
                      <p className="text-[#00EEFD] text-[12px] pl-1">
                        {app.bonus_percentage} %
                      </p>
                    </div>
                    <p className="text-[12px]">bonus</p>
                  </div>
                )}
              </div>

              <div className="flex flex-col mt-[24px] gap-2">
                <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                  From{" "}
                  <div className="flex items-center gap-x-1">
                    <img
                      src={app.asset_images.source_asset_icon}
                      alt="name"
                      className="w-[16px] h-[16px] object-cover"
                    />
                    <span className="font-medium text-white">
                      {app.asset_images.source_asset_symbol}{" "}
                      <span className="text-[#CCCCCC]">
                        (
                        {app.source_chain.charAt(0).toUpperCase() +
                          app.source_chain.slice(1)}
                        )
                      </span>
                    </span>
                  </div>
                </p>
                <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                  To{" "}
                  <div className="flex items-center gap-x-1">
                    <img
                      src={app.asset_images.dest_asset_icon}
                      alt="name"
                      className="w-[16px] h-[16px] object-cover"
                    />
                    <span className="font-medium text-white">
                      {app.asset_images.dest_asset_symbol}{" "}
                      <span className="text-[#CCCCCC]">
                        (
                        {app.dest_chain.charAt(0).toUpperCase() +
                          app.dest_chain.slice(1)}
                        )
                      </span>
                    </span>
                  </div>
                </p>
              </div>
              <p className="text-[15px] mt-10 font-medium">Onboarding link</p>
              <div className="rounded-[40px] mt-[8px] px-4 py-[12px] bg-[#121212] h-[44px] border flex items-center justify-between border-gray-light2">
                <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                  {app.generate_link}
                </p>
                <img
                  src="/assets/main_v4/minicopy.svg"
                  alt={app.title || ""}
                  className="w-[20px] h-[20px] object-cover cursor-pointer"
                  onClick={() =>
                    navigator.clipboard.writeText(app.generate_link || "")
                  }
                />
              </div>
              <div className="flex items-center justify-center mt-6">
                <a
                  href={app.generate_link || ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="rounded-full w-[180px] text-base font-medium h-[40px] bg-primary-default hover:bg-[#9F71FF]">
                    {app.onboarding_type === "staking"
                      ? "Stake now"
                      : app.onboarding_type === "swapping"
                      ? "Swap now"
                      : app.onboarding_type === "lending"
                      ? "Lend now"
                      : ""}
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
   
  );
};

export default OnboardingCard;
