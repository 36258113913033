import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetailsApiType } from "thirdweb/dist/types/wallets/in-app/core/authentication/types";
import { UserDetailsResponse } from "../../pages/MainV3/hooks/types";

interface UserState {
  userDetails: UserDetailsResponse;
  
}
const initialState: UserState = {
  userDetails: {
    data: {
      point: {
        id: "",
        points: null,
        rank: 0,
        user_adddress: "",
        user_id: "",
      },
      user: {
        created_at: "",
        id: "",
        is_active: false,
        referral_code: "",
        updated_at: "",
        wallet_address: "",
        wallet_type: "",
      },
    },
    error: null,
    message: "",
    statusCode: 0,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<UserDetailsResponse | null>) => {
      if(action.payload?.data){
        state.userDetails = action.payload;
      }
    },
    resetUser:(state)=>{
        state.userDetails=initialState.userDetails
    }
  },
});
export const {updateUser, resetUser}=userSlice.actions

export default userSlice.reducer