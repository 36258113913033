import React from "react";

interface CardProps {
  name: string;
  description: string;
  onboardingApps: number;
  logo: string;
  id: string;
  includeEth?: boolean;
  includeBase?: boolean;
  includeMonad?: boolean;
  actions?: () => void;
}

const MiniCard: React.FC<CardProps> = ({
  name,
  description,
  onboardingApps,
  logo,
  includeEth = false,
  includeBase = false,
  includeMonad = true,
  actions,
}) => {
  return (
    <div className="w-[455px] h-[260px] p-6 bg-[#222222] border border-[#666666] rounded-lg flex flex-col justify-between ">
      <div className="flex items-center gap-4">
        <img src={logo} alt={`${name} logo`} className="w-12 h-12" />
        <div>
          <h2 className="text-lg font-semibold text-white">{name}</h2>

          <div className="flex flex-row space-x-2">
            {includeMonad && (
              <img
                className="w-auto h-auto mt-1"
                src="/assets/main_v4/mondalog.svg"
                alt="mona"
              />
            )}
            {includeEth && (
              <img
                className="w-auto h-4 mt-1"
                src="/assets/main_v4/ethcard.svg"
                alt="ethbase"
              />
            )}
            {includeBase && (
              <img
                className="w-auto h-4 mt-1"
                src="/assets/main_v4/basecard.svg"
                alt="baseENV"
              />
            )}
          </div>
        </div>
      </div>
      <p
  className="text-[#BFBFBF] text-sm mt-3 text-[14px] flex-grow leading-normal overflow-hidden"
  style={{
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    lineHeight: "1.2em", 
    maxHeight: "3.6em",  
    textOverflow: "ellipsis",
  }}
>
  {description}
</p>
      <div className="flex items-center justify-between mt-4">
        <span className="text-[#FFFFFF] font-semibold">
          {onboardingApps} onboarding apps
        </span>
        <button
          className=" text-white px-4 py-2 rounded-4xl  border border-[#666666]  focus:outline-none"
          onClick={actions}
        >
          Coming soon
        </button>
      </div>
    </div>
  );
};

export default MiniCard;
