import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useGetAllOnboardingApps, useGetAllProtocols } from "./hooks/helper";
import Tslogin from "./Tsconnect";
import PrevHeader from "./Header";
import { useWallet } from "./hooks/content";
import { EpochFlow } from "./Onboard";
import ExploreDApp from "./Exploredapp";
import Ranking from "./Ranking";
import { AiFillThunderbolt } from "react-icons/ai";
import { ExplorerTabs } from "./hooks/types";

const ExploreView = () => {
  const { dappId } = useParams<{ dappId: string }>();
  const location = useLocation();
  const { state } = location;
  const { name, description, logo, networkUrls, networks } = state as {
    name: string;
    description: string;
    logo: string;
    networkUrls: string[];
    networks: string[];
  };
  console.log("here: ", networks);
  const onboardingAppsData = useGetAllOnboardingApps(dappId || "");
  const onboardingApps = onboardingAppsData.data || [];
  const [isEpochnOpen, setisEpochnOpen] = useState(false);
  const { pubAddress, setPubAddress, connectedWallet } = useWallet();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ExplorerTabs>("Explore apps");

  const handleOConnect = () => {
    setShowLoginModal(true);
  };

  const handleranking = () => {};

  const handleOfConnect = () => {
    setShowLoginModal(false);
  };

  const handleCloseModal = () => {
    setisEpochnOpen(false);
  };

  const disconnectWallet = () => {
    setPubAddress(null);
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("pubAddress");
  };
  useEffect(() => {
    if (pubAddress) {
      setShowLoginModal(false);
    } else {
      setShowLoginModal(false);
    }
  }, [pubAddress]);

  return (
    <div className="flex min-h-screen text-white bg-black">
      {/* Sidebar */}
      <aside className="w-[240px] bg-[#121212] p-6 hidden px-10 md:flex flex-col justify-between">
        <div>
          <div className="flex items-center mt-6 w-[166px]">
            <Link to="/">
              <img src="/assets/main_v4/theloggo.svg" alt="Townesquare logo" />
            </Link>
          </div>
          <img src="/assets/main_v4/sidemask.svg" alt="Townesquare sidemask" />
          <div className="mb-24">
            <button
              onClick={() => setisEpochnOpen(!isEpochnOpen)}
              className="flex items-center justify-between w-[126px] h-[44px] px-8 py-6 -mt-6 gap-2 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
            >
              <span className="text-white text-[15px]">Epoch</span>
              <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px] pr-2 pl-2">
                1
              </span>
            </button>
          </div>
          <nav>
            <ul className="space-y-6 text-sm font-medium">
              <li
                className={`cursor-pointer text-[16px] mb-10 ${
                  activeTab === "Ranking" ? "text-purple-400" : "text-gray-400"
                }`}
                onClick={() => setActiveTab("Ranking")}
              >
                Ranking
              </li>
              <li
                className={`cursor-pointer text-[16px] ${
                  activeTab === "Explore apps" ? "text-purple-400" : "text-gray-400"
                }`}
                onClick={() => setActiveTab("Explore apps")}
              >
                Explore apps
              </li>
            </ul>
          </nav>
        </div>
      </aside>
  
      {/* Main Content */}
      <div className="flex flex-col w-full">
        {activeTab === "Ranking" && (
          <>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <Ranking />
          </>
        )}
  
        {activeTab === "Explore apps" && (
          <>
            <PrevHeader
              pubAddress={pubAddress}
              backbutton={true}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={`${name} Onboarding dApps`}
            />
            <div className="px-8 text-white bg-black">
              <div className="flex items-center gap-4">
                <img
                  src={logo}
                  alt={name}
                  className="w-[80px] h-[80px] rounded-full border border-white/40 object-cover"
                />
                <div>
                  <p className="text-[29px]">{name}</p>
                  <div className="flex flex-row justify-between">
                    {networks?.map((network: any, i) => (
                      <div
                        key={i}
                        className="flex items-center justify-start gap-1 border rounded-[30px] px-2 py-1 border-gray-light-3 mr-2 border-[#666666]"
                      >
                        <img src={network.logo} className="w-[14px] h-[14px]" />
                        <p className="text-white text-[12px]">{network.chain}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-6 text-base text-[14px] text-gray-light2 mt-6  w-[680px]">
                {description}
              </p>
  
              {/* 3 Box for Swapping, Staking, Lending */}
              <div className="flex flex-wrap gap-[16px]">
                {onboardingApps.map((app, index) => (
                  <div
                    key={index}
                    className="p-6 w-[290px] flex flex-col h-[342px] bg-[#222222] border-[#666666] border rounded-lg"
                  >
                    <div className="flex items-center justify-between">
                      <p className="flex text-xl">
                        {app.onboarding_type.charAt(0).toUpperCase() +
                          app.onboarding_type.slice(1)}{" "}
                        app
                      </p>
                      {app.bonus_percentage !== null && app.bonus_percentage > 0 && (
                      <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
                        <div className="flex items-center font-bold">
                          <AiFillThunderbolt size={12} color="#00EEFD" />
                          <p className="text-[#00EEFD] text-[12px] pl-1"> {app.bonus_percentage} % </p>
                        </div>
                        <p className="text-[12px]">bonus</p>
                      </div>
                      )}
                    </div>
                    <div className="flex flex-col mt-[24px] gap-2">
                      <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                        From{" "}
                        <div className="flex items-center gap-x-1">
                          <img
                            src={app.asset_images.source_asset_icon}
                            alt={name}
                            className="w-[16px] h-[16px] object-cover"
                          />
                          <span className="font-medium text-white">
                            {app.asset_images.source_asset_symbol}{" "}
                            <span className="text-[#CCCCCC]">
                              (
                              {app.source_chain.charAt(0).toUpperCase() +
                                app.source_chain.slice(1)}
                              )
                            </span>
                          </span>
                        </div>
                      </p>
                      <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                        To{" "}
                        <div className="flex items-center gap-x-1">
                          <img
                            src={app.asset_images.dest_asset_icon}
                            alt={name}
                            className="w-[16px] h-[16px] object-cover"
                          />
                          <span className="font-medium text-white">
                            {app.asset_images.dest_asset_symbol}{" "}
                            <span className="text-[#CCCCCC]">
                              (
                              {app.dest_chain.charAt(0).toUpperCase() +
                                app.dest_chain.slice(1)}
                              )
                            </span>
                          </span>
                        </div>
                      </p>
                    </div>
                    <p className="text-[15px] mt-10 font-medium">Onboarding link</p>
                    <div className="rounded-[40px] mt-[8px] px-4 py-[12px] bg-[#121212] h-[44px] border flex items-center justify-between border-gray-light2">
                      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                        {app.generate_link}
                      </p>
                      <img
                        src="/assets/main_v4/minicopy.svg"
                        alt={app.title || ""}
                        className="w-[20px] h-[20px] object-cover cursor-pointer"
                        onClick={() =>
                          navigator.clipboard.writeText(app.generate_link || "")
                        }
                      />
                    </div>
                    <div className="flex items-center justify-center mt-6">
                      <a
                        href={app.generate_link || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="rounded-full w-[180px] text-base font-medium h-[40px] bg-primary-default hover:bg-[#9F71FF]">
                          {app.onboarding_type === "staking"
                            ? "Stake now"
                            : app.onboarding_type === "swapping"
                            ? "Swap now"
                            : app.onboarding_type === "lending"
                            ? "Lend now"
                            : ""}
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
         {showLoginModal && <Tslogin onCancelProcess={handleOfConnect} />}
         {isEpochnOpen && <EpochFlow onCancelProcess={handleCloseModal} />}
      </div>
    </div>
)};

export default ExploreView;
