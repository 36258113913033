import React, { useEffect, useState } from "react";
import { getRanking } from "./hooks/helper";

interface UserItem {
  user_id: string;
  user_adddress: string;
  points: string;  
  rank: number;
  isCurrentUser?: boolean;
  createdAt?: string;
}

// Define props for the TopRanking component
interface TopRankingProps {
  title: string;
}

const truncateAddress = (user_adddress: string): string =>
  user_adddress.length > 15
    ? `${user_adddress.slice(0, 9)}...${user_adddress.slice(-6)}`
    : user_adddress;

const TopRanking: React.FC<TopRankingProps> = ({ title }) => {
  const [rankingData, setRankingData] = useState<UserItem[]>([]);

  useEffect(() => {
    const fetchTopRanking = async () => {
      try {
        const response = await getRanking();
        const data = response?.data || [];
        const mappedData = data
          .map((item: any) => ({
            user_id: item.user_id,
            user_adddress: item.user_adddress,
            points: item.points,   
            rank: item.rank,
          }))
          .sort((a: UserItem, b: UserItem) => a.rank - b.rank).slice(0, 100);

        setRankingData(mappedData);
      } catch (error) {
        console.error("Error fetching ranking data:", error);
      }
    };

    fetchTopRanking();
  }, []);

  const generateRandomGradient = () => {
    const randomColor1 = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;
    const randomColor2 = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;
    return `linear-gradient(135deg, ${randomColor1}, ${randomColor2})`;
  };

  return (
    <div>
      <h2 className="font-semibold text-[23px] leading-[29.98px] mb-8">
        {title}
      </h2>
      <div className="w-[588px] flex flex-col gap-8 rounded-[20px] border border-[#666666] overflow-hidden ">
        <ul className="w-full">
          {rankingData.map((item, index) => (
            <li
              key={item.user_id}
              className={`flex items-center gap-4 pr-10 pl-8 py-[14px] w-[588px] rounded-[10px   ${
                index % 2 === 0 ? "bg-[#222222]" : "bg-[#2D2D2D]"
              }`}
            >
              {/* Rank index styling */}
              <div
                className={`flex justify-center items-center w-[38px] h-[38px] rounded-[10px] text-base font-medium  ` }
                style={{
                  backgroundColor:
                    index === 0
                      ? "#FFF5BD"
                      : index === 1
                      ? "#B9B9B9"
                      : index === 2
                      ? "#E77F44"
                      : "#000",
                  color: index < 3 ? "#484747" : "#AAAAAA",
                  border: index < 3 ? "1px solid white" : "1px solid #AAAAAA",
                }}
              >
                {index + 1}
              </div>

              {/* User details */}
              <div className="flex items-center gap-3">
                <div
                  className="w-[34px] h-[34px] rounded-full"
                  style={{ background: generateRandomGradient() }}
                ></div>
                <span className="text-base text-[15px] font-normal text-[#FFFFFF] opacity-80 leading-[21px]">
                  {truncateAddress(item.user_adddress)}
                </span>
              </div>

             
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopRanking;
