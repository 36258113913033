import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllOnboardingApps, useGetAllProtocols } from "./hooks/helper";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillThunderbolt,
  AiOutlineDotChart,
} from "react-icons/ai";
import MiniCard from "./ComingCard";

interface DApp {
  name: string;
  description: string;
  onboardingApps: number;
  logo: string;
  id: string;
  networkUrls: string[];
  networks: string[];
}

const ExploreDApp = () => {
  const navigate = useNavigate();
  const { dappId } = useParams<{ dappId: string }>();
  const [isActive, SetisActive] = useState([]);
  const onboardingAppsData = useGetAllOnboardingApps(dappId || "");
  const onboardingApps = onboardingAppsData.data || [];
  const [highestBonus, setHighestBonus] = useState<number | null>(null);

  const protocols = useGetAllProtocols();
  const dapps = protocols.data || [];

  const filteredDapps = dapps.filter(
    (dapp) => !["Aionex"].includes(dapp.title)
  );

  console.log(dapps, "dapps")

  const handleNavigation = (dappId: string) => {
    const selectedDApp = filteredDapps.find(
      (dapp) => dapp.id.toString() === dappId
    );
    // const selectedDApp = dapps.find((dapp) => dapp.id.toString() === dappId);
    if (selectedDApp) {
      navigate(`/explore/${dappId}`, {
        state: {
          name: selectedDApp.title,
          logo: selectedDApp.logo,
          description: selectedDApp.description,
          networkUrls: selectedDApp.networkUrls,
          networks: selectedDApp.networks,
        },
      });
    }
  };

  useEffect(() => {
    if (onboardingApps.length > 0) {
      const maxBonus = onboardingApps
        .filter(
          (app) => app.bonus_percentage !== null && app.bonus_percentage > 0
        )
        .reduce((max, app) => Math.max(max, app.bonus_percentage || 0), 0);
      setHighestBonus(maxBonus > 0 ? maxBonus : null);
    }
  }, [protocols.data]);

  

  return (
    <div className="px-8 py-4 text-white bg-black ">
      {/* <OnBoardingHeader showProjectName={false} /> */}

      <div className="flex flex-wrap gap-4">
        {/* {dapps.map((dapp, index) => ( */}
        {filteredDapps.map((dapp, index) => (
          <div
            key={index}
            className="flex flex-col justify-between w-[455px] h-[260px] px-6 py-6 border bg-[#222222] rounded-xl border-[#666666]"
          >
            {dapp.actions.length > 0 && (
              <div className={`flex mb-[16px] `}>
                <div className="flex gap-1">
                  {dapp.actions.map((action) => (
                    <div className="w-[67px] h-[28px] rounded-[48px]  px-4 border border-[#666666] flex items-center justify-center">
                      <p className="text-[14px]">{action.action}</p>
                    </div>
                  ))}
                </div>

                {/* {dapp.active_campaign === true && (
                <div className=" h-[28px] rounded-[40px] px-3 border border-[#B882FF] flex items-center justify-center mr-2">
                  <img src="/assets/main_v4/ellipse.svg" className="w-2 h-2 mr-[6px]" />
                  <p className="font-outfit text-[14px] font-normal leading-[17.64px] p-1">Active campaign</p>
                </div>
              )} */}

                {dapp.__onboarding__ &&
                  dapp.__onboarding__.length > 0 &&
                  (() => {
                    const highestBonus = dapp.__onboarding__.reduce(
                      (max, item) => {
                        const bonus = item.bonus_percentage ?? 0;
                        return bonus > max ? bonus : max;
                      },
                      0
                    );
                    return highestBonus > 0 ? (
                      <div className="flex justify-end w-full ">
                        <div className=" h-[28px] min-w-max rounded-[40px] px-3 border border-[#B882FF] flex items-center justify-center mr-2">
                          <img
                            src="/assets/main_v4/ellipse.svg"
                            className="w-2 h-2 mr-[6px]"
                          />
                          <p className="font-outfit text-[14px] font-normal leading-[17.64px] p-1">
                            Active campaign
                          </p>
                        </div>
                        <div className="border flex border-[#00EEFD] items-center justify-center gap-1 px-2 rounded-[20px] h-[28px]">
                          <div className="flex items-center font-bold">
                            <AiFillThunderbolt size={12} color="#00EEFD" />
                            <p className="text-[#00EEFD] text-[12px] pl-1">
                              {" "}
                              {highestBonus}%{" "}
                            </p>
                          </div>
                          <p className="text-[12px]">bonus</p>
                        </div>
                      </div>
                    ) : null;
                  })()}
              </div>
            )}

            <div className="flex items-center gap-4">
              <img
                //src={apriorlogo}
                src={dapp.logo}
                alt={dapp.title}
                className="w-[47px] h-[47px] object-cover rounded-full"
              />

              <div className="relative flex-grow">
                <div className="flex flex-row justify-between gap-8">
                  <h2 className="text-xl font-semibold">{dapp.title}</h2>

                  {/* {dapp.__onboarding__ &&
                    dapp.__onboarding__.length > 0 &&
                    (() => {
                      const highestBonus = dapp.__onboarding__.reduce(
                        (max, item) => {
                          const bonus = item.bonus_percentage ?? 0;
                          return bonus > max ? bonus : max;
                        },
                        0
                      );
                      return highestBonus > 0 ? (
                        <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
                          <div className="flex items-center font-bold">
                            <AiFillThunderbolt size={12} color="#00EEFD" />
                            <p className="text-[#00EEFD] text-[12px] pl-1">
                              {" "}
                              {highestBonus} %{" "}
                            </p>
                          </div>
                          <p className="text-[12px]">bonus</p>
                        </div>
                      ) : null;
                    })()} */}
                </div>
                <div className="flex grid-col space-x-2 mt-[6px]">
                  {dapp.networks?.map((network, i) => (
                    <div className=" w-[20px] h-[20px] rounded-full border-[1px] border-[#666666] flex items-center justify-center">
                      <img
                        className="w-[12px] h-[12px]"
                        src={network.logo}
                        alt={dapp.title}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {dapp.description && (
              <p className="text-sm text-gray-400">
                {dapp.description.slice(0, 130)}...
              </p>
            )}
            <div className="flex items-center justify-between">
              <p className="font-semibold">
                {dapp.onboardingCount} onboarding apps
              </p>

              {[
                "Aionex",
                "Modus",
                "Pancakeswap",
                "Magma",
                "Narwhal Finance",
                "Kintsu",
                "Thunderhead"
              ].includes(dapp.title) ? (
                <button className="text-white px-4 py-2 rounded-4xl border border-[#666666] focus:outline-none">
                  Coming soon
                </button>
              ) : (
                <button
                  onClick={() => handleNavigation(dapp.id.toString())}
                  className="h-[42px] px-6 border bg-[#9264F8] font-semibold border-primary-default hover:bg-[#9F71FF] text-white rounded-full flex items-center justify-center cursor-pointer"
                >
                  Enter
                </button>
              )}
            </div>
          </div>
        ))}
        <MiniCard
          name={"Composite Labs"}
          description={
            "A fully decentralized all-in-one trading platform on Monad, featuring cross-margin trading, spot trading, borrowing and lending, perpetual contracts (perps)"
          }
          onboardingApps={0}
          logo={"/assets/main_v4/lab.svg"}
          id={""}
        />
        <MiniCard
          name={"Aionex"}
          description={
            "Aionex is a groundbreaking DEX on Monad featuring a high-performance on-chain CLOB, designed for fully decentralized perpetual trading with unmatched scalability and efficiency."
          }
          onboardingApps={0}
          logo={"/assets/main_v4/Aionex.svg"}
          id={""}
        />
         
        <MiniCard
          name={"Bean Exchange"}
          description={
            "Gamified on-chain Spot & Perpetual DEX natively built on Monad ⨀ Powered by Pyth"
          }
          onboardingApps={0}
          logo={"/assets/main_v4/dbean.svg"}
          id={""}
        />
        
        <MiniCard
          name={"Perpl"}
          description={"Trade on Monad's native pricing engine"}
          onboardingApps={0}
          logo={"/assets/main_v4/ppearl.svg"}
          id={""}
        />
      </div>
    </div>
  );
};

export default ExploreDApp;
