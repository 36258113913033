import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { OutputToken } from "./types";

interface WalletContextProps {
  pubAddress: string | null;
  setPubAddress: (pubAddress: string | null) => void;
  connectedWallet: "metamask" | "phantom" | "backpack" | null;
  setConnectedWallet: (
    wallet: "metamask" | "phantom" | "backpack" | null
  ) => void;
}

const WalletContext = createContext<WalletContextProps | undefined>(undefined);

export const WalletProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pubAddress, setPubAddress] = useState<string | null>(null);
  const [connectedWallet, setConnectedWallet] = useState<
    "metamask" | "phantom" | "backpack" | null
  >(null);

  useEffect(() => {
    // Load the address from sessionStorage on component mount
    const storedAddress = sessionStorage.getItem("pubAddress");
    if (storedAddress) {
      setPubAddress(storedAddress);
    }
  }, []);

  useEffect(() => {
    // Save the address to sessionStorage whenever it changes
    if (pubAddress) {
      sessionStorage.setItem("pubAddress", pubAddress);
    } else {
      sessionStorage.removeItem("pubAddress"); // Clear storage if address is null
    }
  }, [pubAddress]);

  const contextValue = {
    pubAddress,
    setPubAddress,
    connectedWallet,
    setConnectedWallet,
  };

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextProps => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};

interface AggregatorContext {
  outputToken: OutputToken | null;
  handleSetOutputToken: (outputToken: OutputToken) => void;
}

const AggregatorContext = createContext<AggregatorContext | undefined>(
  undefined
);

export const AggregatorProvider = ({ children }: { children: ReactNode }) => {
  const [outputToken, setOutputToken] = useState<OutputToken | null>(null);

  const handleSetOutputToken = (outputToken: OutputToken) => {
    setOutputToken(outputToken);
  };

  return (
    <AggregatorContext.Provider value={{ outputToken, handleSetOutputToken }}>
      {children}
    </AggregatorContext.Provider>
  );
};

export const useAggregator = () => {
  const context = useContext(AggregatorContext);
  if (!context) {
    throw new Error("useAggregator must be used within an AggregatorProvider");
  }
  return context;
};
