import { Route, Routes } from "react-router-dom";
import MainV3 from "./pages/MainV3";
import { RotationProvider } from "./RotationContext";
import { Helmet } from "react-helmet";
import OnboardPage from "./pages/MainV3/Onboard";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ExploreDApp from "./pages/MainV3/Exploredapp";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ExploreView from "./pages/MainV3/ExploreView";
import { WagmiProvider } from "wagmi";

import { AggregatorProvider, WalletProvider } from "./pages/MainV3/hooks/content";
import { config } from "process";

const queryClient = new QueryClient();

function App() {
  return (
    <div>
      {/* <Helmet>
        <meta name="og:type" content="website" />
        <meta
          name="og:title"
          content="Onboarding users to Web 3 in just several clicks"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Web3 Social Network for"
          data-react-helmet="true"
        />
   
        />
      </Helmet> */}
      <RotationProvider>
        <QueryClientProvider client={queryClient}>
          <WalletProvider>
            <AggregatorProvider>
            <Routes>
              <Route path="/" element={<MainV3 />} />
              <Route path="/onboard" element={<OnboardPage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/explore/:dappId" element={<ExploreView />} />
              <Route path="/Explore" element={<ExploreDApp />} />
            </Routes>
            </AggregatorProvider>
          </WalletProvider>
        </QueryClientProvider>
      </RotationProvider>
    </div>
  );
}

export default App;
