import React, { useEffect, useState } from "react";
import NewcomerComp from "./newcommeritem";
import TopRanking from "./Topranking";

 

const UserRanking = () => {
  return (
    <div className=" relative z-10  flex justify-center  pt-10 gap-6 bg-black text-white px-8">
      <TopRanking title={"Top 100 ranked users"}/>
     <NewcomerComp title={"TowneSquare newcomers"}/>
    </div>
  );
};

export default UserRanking;