import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useWallet } from "./hooks/content";
import LoginButton from "./LoginBut";
import { useConnect } from "thirdweb/react";
import { getUserDetails, getWalletIcon } from "./hooks/helper";
import { createThirdwebClient } from "thirdweb";
import { createWallet, injectedProvider } from "thirdweb/wallets";
import {
  checkUserExist,
  signInWithWallet,
  signUpWithWallet,
} from "./hooks/helper";
import { walletType } from "./hooks/types";
import { ReferralPageModal } from "./Onboard";
import { useAppDispatch } from "../../controller/state/hooks";
import { updateUser } from "../../controller/profile/user";

export const handleWalletConnection = async (
  address: string,
  walletType: string,
  setPubAddress: (addr: string) => void,
  setConnectedWallet: (type: string) => void,
  session: Storage
) => {
  setPubAddress(address);
  session.setItem("pubAddress", address);
  setConnectedWallet(walletType);
};
interface TsloginProps {
  onCancelProcess: () => void;
}

const client = createThirdwebClient({
  clientId: "755026d5ac44074afd5557d8cd2f8d83",
});
const Tslogin: React.FC<TsloginProps> = ({ onCancelProcess }) => {
  const { pubAddress, setPubAddress, setConnectedWallet } = useWallet();
  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const [walletTypeState, setWalletTypeState] = useState<string | null>(null);

  const { connect, isConnecting, error } = useConnect();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const wallet = createWallet("app.backpack");

  const handleOpenReferralModal = () => {
    setReferralModalOpen(true);
  };

  const handleCloseReferralModal = () => {
    setReferralModalOpen(false);
  };

  const ConnectBackPack = async () => {
    try {
      if (injectedProvider("app.backpack")) {
        console.log("Backpack wallet detected, attempting to connect...");
        const connection = await wallet.connect({ client });
        console.log("Connected to Backpack wallet successfully!");
        setPubAddress(connection.address);
        sessionStorage.setItem("pubAddress", connection.address);
        sessionStorage.setItem("walletType", "Backpack");
        console.log("Connected account address:", connection.address);
        setConnectedWallet(walletType.BackPack);
        const userRecord = await checkUserExist({
          accountAddress: connection.address,
          walletType: walletType.BackPack,
        });
        if (userRecord.statusCode === 201) {
          if (userRecord.data) {
            console.log("User exists, logging in...");
            const res = await signInWithWallet({
              accountAddress: connection.address,
              walletType: walletType.BackPack,
            });
            sessionStorage.setItem("token", res.data.token);
            localStorage.setItem("token", res.data.token);
            console.log("Logged in successfully!");
            const userDetails = await getUserDetails();
            dispatch(updateUser(userDetails));
          } else {
            console.log("User does not exist, signing up...");
            const res = await signUpWithWallet({
              accountAddress: connection.address,
              walletType: walletType.BackPack,
            });
            sessionStorage.setItem("token", res.data.token);
            localStorage.setItem("token", res.data.token);
            console.log("Account created successfully!");
            setReferralModalOpen(true);
            const userDetails = await getUserDetails();
            dispatch(updateUser(userDetails));
          }
        } else if (userRecord.statusCode === 404) {
          console.log("User does not exist, signing up...");
          const res = await signUpWithWallet({
            accountAddress: connection.address,
            walletType: walletType.BackPack,
          });
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          console.log("Account created successfully!");
          const userDetails = await getUserDetails();
          dispatch(updateUser(userDetails));
          setReferralModalOpen(true); // Open the referral modal here
        } else {
          console.error("Unexpected response:", userRecord);
          console.error(
            "An unexpected error occurred while checking user existence."
          );
        }
      } else {
        console.error("Backpack wallet not detected");
        window.open("https://backpack.app/", "_blank");
      }
    } catch (error) {
      console.error("Failed to connect to Backpack wallet:", error);
      alert("Failed to connect to Backpack wallet. Please try again.");
    }
  };

  const checkMetamaskIsInstalled = () => {
    return typeof window.ethereum !== "undefined";
  };

  const connectMetamask = async () => {
    console.log("==== connectMetamask ====");
    if (!checkMetamaskIsInstalled()) {
      alert("Please install MetaMask");
      return;
    }
    try {
      const accounts = (await window.ethereum!.request?.({
        method: "eth_requestAccounts",
      })) as string[];

      if (accounts && accounts.length > 0) {
        const accountAddress = accounts[0];
        setPubAddress(accountAddress);
        sessionStorage.setItem("pubAddress", accountAddress);
        setConnectedWallet(walletType.Metamask);

        console.log("Connected account:", accountAddress);
        // alert(`Connected account: ${accountAddress}`);
        sessionStorage.setItem("walletType", "metamask");

        // Check if user exists
        const userRecord = await checkUserExist({
          accountAddress,
          walletType: walletType.Metamask,
        });

        if (userRecord.statusCode === 201 && userRecord.data) {
          // User exists, log in
          console.log("User exists, logging in...");
          const res = await signInWithWallet({
            accountAddress,
            walletType: "metamask",
          });
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          console.log("Logged in successfully!");
          const userDetails = await getUserDetails();
          dispatch(updateUser(userDetails));
          setTimeout(() => {
            handleOpenReferralModal();
          }, 0);
        } else {
          // User does not exist, sign up
          console.log("User does not exist, signing up...");
          const res = await signUpWithWallet({
            accountAddress,
            walletType: walletType.Metamask,
          });
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          console.log("Account created successfully!");
          const userDetails = await getUserDetails();
          dispatch(updateUser(userDetails));
          handleOpenReferralModal();
        }
      } else {
        console.warn("No accounts found");
      }
    } catch (err) {
      console.log("Failed to connect wallet", err);
      console.error("Failed to connect to metamask wallet. Please try again.");
    }
  };

  const getPhantomProvider = (): any | undefined => {
    if ("phantom" in window) {
      console.log("Phantom wallet detected");
      const anyWindow: any = window;
      const provider = anyWindow.phantom;

      return provider;
    } else {
      alert("Please install Phantom wallet");
      window.open("https://phantom.app/", "_blank");
    }
  };

  const connectPhantomWallet = async () => {
    const provider = getPhantomProvider();
    if (!provider) {
      return;
    }
    try {
      const accounts = await provider?.ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("accounts", accounts);

      if (accounts && accounts.length > 0) {
        const accountAddress = accounts[0];
        setPubAddress(accountAddress);
        sessionStorage.setItem("pubAddress", accountAddress);
        console.log("Connected account:", accountAddress);
        // alert(`Connected account: ${accountAddress}`);
        setConnectedWallet(walletType.Phantom);
        sessionStorage.setItem("walletType", "Phantom");
        console.log("walletin :", walletType.Phantom);

        // Check if user exists
        const userRecord = await checkUserExist({
          accountAddress,
          walletType: walletType.Phantom,
        });
        if (userRecord.statusCode === 201 && userRecord.data) {
          // User exists, log in
          console.log("User exists, logging in...");
          const res = await signInWithWallet({
            accountAddress,
            walletType: "phantom",
          });
          console.log("Logged in successfully!");
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          const userDetails = await getUserDetails();
          dispatch(updateUser(userDetails));
          setTimeout(() => {
            handleOpenReferralModal();
          }, 0);
        } else {
          // User does not exist, sign up
          console.log("User does not exist, signing up...");
          const res = await signUpWithWallet({
            accountAddress,
            walletType: walletType.Phantom,
          });
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          console.log("Account created successfully!");
          handleOpenReferralModal();
          const userDetails = await getUserDetails();
          dispatch(updateUser(userDetails));
        }
      } else {
        console.warn("No accounts found");
      }
    } catch (err) {
      console.log("Failed to connect wallet", err);
      console.error("Failed to connect to Phantom wallet. Please try again.");
    }
  };

  const disconnectWallet = () => {
    setPubAddress(null);
    sessionStorage.removeItem("pubAddress");
  };

  useEffect(() => {
    const storedAddress = sessionStorage.getItem("pubAddress");
    if (storedAddress) {
      setPubAddress(storedAddress);

      const storedWalletType = sessionStorage.getItem("walletType") as
        | "metamask"
        | "phantom"
        | "backpack"
        | null;

      if (storedWalletType) {
        console.log("Stored Wallet Type:", storedWalletType);
        setConnectedWallet(storedWalletType);
      } else {
        setConnectedWallet(null);
      }

      if (storedWalletType === "metamask" && checkMetamaskIsInstalled()) {
        connectMetamask();
      } else if (storedWalletType === "phantom" && getPhantomProvider()) {
        connectPhantomWallet();
      } else if (storedWalletType === "backpack") {
        ConnectBackPack();
      }
    }
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      <div className="relative flex flex-col items-center bg-[#222222] rounded-2xl p-6 w-[557px] h-[400px] mt-20 border border-[#FFFFFF66]">
        <button
          className="absolute top-4 right-8 hover:bg-black"
          onClick={onCancelProcess}
        >
          <img src="/assets/main_v4/clos.svg" className="px-1" alt={"close"} />
        </button>
        <p className="py-4 mb-4 text-xl text-center">
          Connect wallet to continue
        </p>
        <div className="flex flex-col gap-2">
          <LoginButton
            title="Phantom"
            showBoost={true}
            roundedTop={true}
            boost={40}
            logo={getWalletIcon("phantom")}
            onClick={connectPhantomWallet}
          />

          <LoginButton
            title=" Backpack"
            logo={getWalletIcon("backpack")}
            showBoost={true}
            boost={30}
            onClick={ConnectBackPack}
          />

          <LoginButton
            title="MetaMask"
            logo={getWalletIcon("metamask")}
            roundedBottom={true}
            onClick={connectMetamask}
          />
        </div>

        {/* <button onClick={handleOpenReferralModal}>
          <p> Referral Test</p>
        </button> */}

        <div className="w-[439px] h-[1px] mt-[40px] bg-[#404040]"> </div>
        <div className="flex items-center justify-center mt-6">
          <p className="text-sm font-medium text-[#AAA] px-2">Powered by </p>
          <img
            src="/assets/main_v3/ts-logo.png"
            alt="Townesquare Logo"
            className="h-[19px] w-[95px]"
          />
        </div>
      </div>
      {isReferralModalOpen && (
        <ReferralPageModal onCancelProcess={handleCloseReferralModal} />
      )}
    </div>
  );
};

export default Tslogin;
