import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ApiResponse, checkUserResponse, GetNewcommerResponse, HistoryResponse, OnboardingApp, Protocol, signInResponse, signUpResponse,UserDetailsResponse,walletType } from './types';
import { BACKEND_URL } from './env';
import Web3 from "web3";
import { getBalance } from "@wagmi/core";
import { mainnet } from "@wagmi/core/chains";
import { ethers } from "ethers";
import { useWallet } from './content';
 

export const getAllProtocol = async (): Promise<Protocol[]> => {
  const token = localStorage.getItem('token');
  try {
    const res = await axios.get<ApiResponse<Protocol[]>>(
      `${BACKEND_URL}api/projects`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};


 


export async function getNewComer() {
  try {
    const response = await fetch('https://backend.townesquare.xyz/rank/new');
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Response data:', data);
    return data;  
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;  
  }
}

export async function getNewusers(): Promise<GetNewcommerResponse | null> {
  try {
    const response = await fetch("https://action-link-stage.townesquare.xyz/api/users");
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("newcommer Response data:", data);
    return data as GetNewcommerResponse;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null; 
  }
}

export async function get2Ranking() {
  try {
    const response = await fetch(`https://backend.townesquare.xyz/rank/top`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Response data:', data);
    return data;  
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;  
  }
}

export async function getRanking() {
  try {
    const response = await fetch(`https://action-link-stage.townesquare.xyz/api/users/ranks`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Response rank data:', data);
    return data;  
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;  
  }
}

 




export const getOnboardingAppsForProject = async (project_id: string) => {
    const token = localStorage.getItem('token');
    try {
      const res = await axios.get<ApiResponse<OnboardingApp[]>>(
        `${BACKEND_URL}api/onboardings?project_id=${project_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.statusCode === 200 && res.data.data) {
        return res.data.data;
      } else return [];
    } catch (error) {
      return [];
    }
  };


  export const getOnboardingAppsForProjects = async () => {
    const token = localStorage.getItem('token');
    try {
      const res = await axios.get<ApiResponse<OnboardingApp[]>>(
        `${BACKEND_URL}api/onboardings/get-all-onboarding`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.statusCode === 200 && res.data.data) {
        return res.data.data;
      } else return [];
    } catch (error) {
      return [];
    }
  };


  


  export const getPhantomProvider = (): any | undefined => {
    if ("phantom" in window) {
      console.log("Phantom wallet detected");
      const anyWindow: any = window;
      const provider = anyWindow.phantom;
  
      if (provider) {
        console.log(provider);
        return provider;
      }
    } else alert("Please install Phantom wallet");
  
    window.open("https://phantom.app/", "_blank");
  };
  
  export const requestPhantomWallet = async () => {
    const provider = getPhantomProvider();
    if (!provider) return;
    try {
      const accounts = await provider?.ethereum.request({
        method: "eth_requestAccounts",
      });
    } catch (err) {
      console.log("Failed to connect wallet", err);
    }
  };

  export const getUsdcBalance = async (address: string) => {
    const web3_instance = new Web3(
      "https://sepolia.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d"
    );
    const abiErc20 = [
      {
        constant: true,
        inputs: [{ name: "who", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];
    const usdcContract = "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238";
    const usdtContractInstance = new web3_instance.eth.Contract(
      abiErc20,
      usdcContract
    );
  
    const balance = await usdtContractInstance.methods.balanceOf(address).call();
    return Number(balance) / 10 ** 6;
  };
  
  export const chainRPCS = [
    {
      chainHex: "0x1",
      chainId: 1,
      chainName: "Mainnet",
      rpcUrls: ["https://mainnet.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d"],
      blockExplorerUrls: ["https://etherscan.io/"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
    },
    {
      chainHex: "0x2105",
      chainId: 8453,
      chainName: "Base",
      rpcUrls: [
        "https://base-mainnet.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d",
      ],
      blockExplorerUrls: ["https://basescan.org/"],
      nativeCurrency: {
        name: "BASE",
        symbol: "BASE",
        decimals: 18,
      },
    },
  ];
  
  export const switchMetamaskNetwork = async (chainId: number) => {
    if (!window.ethereum) return alert("MetaMask is not installed");
  
    const chainDetails = chainRPCS.find((chain) => chain.chainId === chainId);
    if (!chainDetails) {
      return alert("Chain details not found");
    }
  
    try {
      if (window.ethereum?.request) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainDetails.chainHex }],
        });
        // Listen for the `chainChanged` event
      } else {
        throw new Error("Ethereum request method not available");
      }
    } catch (error: any) {
      if (error.code === 4902) {
        // Chain has not been added
        try {
          if (window.ethereum?.request) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: chainDetails.chainHex,
                  chainName: chainDetails.chainName,
                  rpcUrls: chainDetails.rpcUrls,
                  nativeCurrency: chainDetails.nativeCurrency,
                  blockExplorerUrls: chainDetails.blockExplorerUrls,
                },
              ],
            });
          } else {
            throw new Error("Ethereum request method not available");
          }
        } catch (addError) {
          console.error("Failed to add network:", addError);
        }
      } else {
        console.error("Failed to switch network:", error);
      }
    }
  };
  
  export const switchPhantomNetwork = async (chainId: number) => {
    const provider = getPhantomProvider();
    if (!provider) return;
    const chainDetails = chainRPCS.find((chain) => chain.chainId === chainId);
    if (!chainDetails) {
      return alert("Chain details not found");
    }
    await requestPhantomWallet();
  
    try {
      await provider.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainDetails.chainHex }],
      });
      console.log(`Switched to network ${chainId}`);
    } catch (switchError: any) {
      if (switchError?.code === 4902) {
        console.log("Network has not been added");
        // Chain has not been added
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainDetails.chainHex,
                chainName: chainDetails.chainName,
                rpcUrls: chainDetails.rpcUrls,
                nativeCurrency: chainDetails.nativeCurrency,
                blockExplorerUrls: chainDetails.blockExplorerUrls,
              },
            ],
          });
          console.log(`Added and switched to network ${chainId}`);
        } catch (addError) {
          console.error("Failed to add network", addError);
        }
      } else {
        console.error("Failed to switch network", switchError);
      }
    }
  };
  
 
  
 
  
  export async function getCurrentPhantomChainId() {
    const provider = getPhantomProvider();
    const network = await provider.ethereum.chainId;
    const networkToNumber = parseInt(network, 16);
  
    console.log("===== phantom chain id =====");
    console.log(networkToNumber);
    return networkToNumber;
  }


export const useGetAllProtocols = () => {
    const getProtocols = async () => {
      const response = await getAllProtocol();
      return response;
    };
  
    return useQuery({
      queryKey: ['getAllProtocols'],
      queryFn: () => getProtocols(),
    });
  };
 

  export const useGetAllOnboardingApps = (projectId: string) => {
    const getOnboardingApps = async () => {
      const response = await getOnboardingAppsForProject(projectId);
      return response;
    };
    return useQuery({
      queryKey: ['getOnboardingApps', projectId],
      queryFn: () => getOnboardingApps(),
    });
  };
  


  export const useGetAllOnboardingLinks = () => {
    const getOnboardingApps = async () => {
      const response = await getOnboardingAppsForProjects();
      return response;
    };
    return useQuery({
      queryKey: ['getOnboardingApps'],
      queryFn: () => getOnboardingApps(),
    });
  };



  export const checkUserExist = async ({
    accountAddress,
    walletType,
  }: {
    accountAddress: string;
    walletType: walletType;
  }): Promise<checkUserResponse> => {
    try {
      const response = await axios.post(
        `https://action-link-stage.townesquare.xyz/api/users/check-user-exist`,
        {
          wallet_type: walletType.toLowerCase(),
          wallet_address: accountAddress,
        }
      );
      return {
        statusCode: response.data.statusCode,
        data: response.data.data,
      };
    } catch (error: unknown) {
      
      const axiosError = error as AxiosError;
  
      console.error(axiosError.response?.data, "check-record");
  
      return {
        statusCode: axiosError.response?.status || 500,
        data: {
          id: "",
          is_active: false,
          referral_code: "",
          wallet_address: "",
          wallet_type: "",
          created_at: "",
          updated_at: "",
        },
      };
    }
  };
  
  

  export const Appplyreferral = async ({
    referralcode,
  }: {
    referralcode: string;
  }) => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token available");
      return { success: false, message: "User is not authenticated" };
    }
  
    try {
      const response = await axios.post(
        `https://action-link-stage.townesquare.xyz/api/users/referrals`,
        { referral_code: referralcode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
     // console.log("Referral response", response.data);
      return { success: true, message: "Referral code applied successfully" };
    } catch (error: any) {
      console.error("Error applying referral code", error);
      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred";
      return { success: false, message: errorMessage };
    }
  };

  

  export const signInWithWallet = async ({
    accountAddress,
    walletType,
  }: {
    accountAddress: string;
    walletType: string;
  }): Promise<signInResponse> => {
    try {
      const response = await axios.post(
        `https://action-link-stage.townesquare.xyz/api/users/sign-in`,
        {
          wallet_type: walletType.toLowerCase(),
          wallet_address: accountAddress,
        },
        
        {
          headers: {
            "Content-Type": "application/json",
          },
          
        }
      );
     // console.log("sign in response", response);
      return {
        
        statusCode: response.data.statusCode,
        data: response.data.data,
      };
      
    } catch (error) {
      return {
        statusCode: 400,
        data: {
          token: "",
        },
        error: error,
      };
    }
  };

  export const signUpWithWallet = async ({
    accountAddress,
    walletType,
  }: {
    accountAddress: string;
    walletType: walletType;
  }): Promise<signUpResponse> => {
    try {
      const response = await axios.post(`https://action-link-stage.townesquare.xyz/api/users/sign-up`, {
        wallet_type: walletType.toLowerCase(),
        wallet_address: accountAddress,
      });
     // console.log("signUpWithWallet response:", response);
      return {
        statusCode: response.data.statusCode,
        data: response.data.data,
      };
    } catch (error) {
      console.error(error, "signInWithWallet");
      return {
        statusCode: 400,
        data: {
          token: "",
        },
        error: error,
      };
    }
  };

  
  export const getUserDetails = async (): Promise<UserDetailsResponse | null> => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token available");
        return null;
      }
      console.log("Token retrieved:", token); 
      const response = await axios.get(`${BACKEND_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = response;
      if (data?.statusCode === 200 && data?.data) {
       console.log("User Details:", data.data); 
        return data as UserDetailsResponse;
      } else {
        console.error("Unexpected response structure:", data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };
  

  

  export const getAllHistory = async (userAddress: string): Promise<HistoryResponse[]> => {
    const token = localStorage.getItem("token");
  
   // console.log("add and token", token, userAddress);
  
    if (!token || !userAddress) {
      console.error("Missing token or user address");
      return [];
    }
    try {
      const res = await axios.get<ApiResponse<HistoryResponse[]>>(
        `${BACKEND_URL}api/transactions/history?user_address=${userAddress}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.statusCode === 200 && res.data.data) {
       console.log("======History Response", res.data);
        return res.data.data;
      } else {
        console.warn("Unexpected response:", res.data);
        return [];
      }
    } catch (error) {
      console.error("Error fetching history:", error);
      return [];
    }
  };

  // Function to get the wallet icon based on walletType
export const getWalletIcon = (walletType: string) => {
  switch (walletType.toLowerCase()) {
    case "metamask":
      return "/assets/main_v4/metamask.svg";
    case "phantom":
      return "/assets/main_v4/phantom.svg";
    case "backpack":
      return "/assets/main_v4/backpack.svg";
    default:
      return "/assets/main_v4/default-wallet.svg";
  }
};

export const getWalletBonus = (walletType: string) => {
  switch (walletType.toLowerCase()) {
    case "metamask":
      return "-";
    case "phantom":
      return "+40%";
    case "backpack":
      return "+30%";
    default:
      return "-";
  }
};


